import * as React from 'react';
import { useAppSelector } from '../../store/hooks';
import SelectComponent, { SelectOption } from './select.component';
import { GroupAddGroupToOrder, GroupData } from '../../store/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { groupAddGroupSchema, groupAddGroupInitialState } from './schemas/groupAddGroup.schema';

interface GroupGroupModalType {
  handleSelectedGroup: (groupId: number) => void;
  modalHref: string;
}

function GroupGroupModal({ handleSelectedGroup, modalHref }: GroupGroupModalType) {
  const closeModalRef = React.useRef<any>();
  const [groupOptions, setGroupOptions] = React.useState<SelectOption[]>([]);
  const allGroups = useAppSelector((state) => state.groups.groups);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<GroupAddGroupToOrder>({
    resolver: yupResolver(groupAddGroupSchema)
  });

  React.useEffect(() => {
    if (allGroups && allGroups.length) {
      const optionsTmp: SelectOption[] = [];

      optionsTmp.push({ id: 0, value: 'Wszyscy' });
      allGroups.map((group: GroupData) => {
        if (group.id && group.employeeCount && group.employeeCount > 0) {
          optionsTmp.push({ id: group.id, value: group.name });
        }
      });

      setGroupOptions(optionsTmp);
    }
  }, [allGroups]);

  const onSubmit = handleSubmit(async (data: GroupAddGroupToOrder) => {
    if (data.groupId) {
      handleSelectedGroup(parseInt(data.groupId));
      reset({ ...groupAddGroupInitialState });
    }
    closeModalRef.current.click();
  });

  return (
    <>
      <div className="ued-modal modal fade" id={modalHref} tabIndex={-1} role="dialog" aria-labelledby={modalHref} aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addGroupProductToOrder">
                Wybierz grupę
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <span>Wybierz grupę użytkowników z poniższej listy, których chcesz dodać do zamówienia.</span>
              <form className="mt-3" id="groupAddUserFrom" onSubmit={onSubmit}>
                <div className="row">
                  <div className="col-12">
                    <SelectComponent register={register} fieldName="groupId" label="Grupa" errors={errors} optionData={groupOptions} />
                  </div>

                  <div className="col-12 d-flex justify-content-end">
                    <button type="button" ref={closeModalRef} data-dismiss="modal" className="submit-button hidden-button btn btn-primary"></button>
                    <button type="button" className="cancel-button btn btn-secondary mr-2" data-dismiss="modal">
                      NIE
                    </button>
                    <button type="submit" onClick={() => {}} className="submit-button btn btn-primary">
                      TAK, DODAJ
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GroupGroupModal;
