import * as React from 'react';
import { useAppSelector } from '../../store/hooks';
import SelectComponent, { SelectOption } from './select.component';
import { EmployeeData, GroupAddUserToOrder } from '../../store/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { groupAddUserSchema, groupAddUserInitialState } from './schemas/groupAddEmployee.schema';

interface GroupUserModalType {
  handleSelectedUser: (userId: number) => void;
  modalHref: string;
}

function GroupUserModal({ handleSelectedUser, modalHref }: GroupUserModalType) {
  const closeModalRef = React.useRef<any>();
  const [employeeOptions, setUserOptions] = React.useState<SelectOption[]>([]);
  const allEmployees = useAppSelector((state) => state.groups.employees);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(groupAddUserSchema)
  });

  React.useEffect(() => {
    if (allEmployees && allEmployees.length) {
      const optionsTmp: SelectOption[] = [];

      allEmployees.map((user: EmployeeData) => {
        if (user.id) {
          optionsTmp.push({ id: user.id, value: `${user.firstName} ${user.lastName}` });
        }
      });

      setUserOptions(optionsTmp);
    }
  }, [allEmployees]);

  const onSubmit = handleSubmit(async (data: Partial<GroupAddUserToOrder>) => {
    if (data.userId) {
      handleSelectedUser(parseInt(data.userId));
      reset({ ...groupAddUserInitialState });
    }
    closeModalRef.current.click();
  });

  return (
    <>
      <div className="ued-modal modal fade" id={modalHref} tabIndex={-1} role="dialog" aria-labelledby={modalHref} aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addGroupUserToorder">
                Wybierz użytkownika
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <span>Wybierz użytkownika z poniższej listy, którego chcesz dodać do zamówienia.</span>
              <form className="mt-3" id="groupAddUserFrom" onSubmit={onSubmit}>
                <div className="row">
                  <div className="col-12">
                    <SelectComponent register={register} fieldName="userId" label="Użytkownik" errors={errors} optionData={employeeOptions} />
                  </div>

                  <div className="col-12 d-flex justify-content-end">
                    <button type="button" ref={closeModalRef} data-dismiss="modal" className="submit-button hidden-button btn btn-primary"></button>
                    <button type="button" className="cancel-button btn btn-secondary mr-2" data-dismiss="modal">
                      NIE
                    </button>
                    <button type="submit" onClick={() => {}} className="submit-button btn btn-primary">
                      TAK, DODAJ
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GroupUserModal;
