import { EmployeeData } from '../../store/types';

interface GroupEmployeesModalType {
  employees: EmployeeData[];
  groupName: string;
}

function GroupEmployeesModal({ employees, groupName }: GroupEmployeesModalType) {
  const renderEmployeeList = () => {
    return (
      <ul className="mt-4">
        {employees.map((employee: EmployeeData) => {
          return <li>{`${employee.firstName} ${employee.lastName}`}</li>;
        })}
      </ul>
    );
  };

  return (
    <>
      <div
        className="ued-modal modal fade"
        id="groupEmployeesModal"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="groupEmployeesModal"
        aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="groupEmployeeModalData">
                Lista użytkowników grupy {groupName}
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <span>Wybierz grupę użytkowników z poniższej listy, których chcesz dodać do zamówienia.</span>
              <div className="row">
                <div className="col-12">{renderEmployeeList()}</div>

                <div className="col-12 d-flex justify-content-end">
                  <button type="button" className="cancel-button btn btn-secondary mr-2" data-dismiss="modal">
                    ZAMKNIJ
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GroupEmployeesModal;
