import * as React from 'react';

interface RejectionModalType {
  handleRejectOrder: Function;
  selectedOrderIndex: number | null;
}

function RejectionModalComponent({ handleRejectOrder, selectedOrderIndex }: RejectionModalType) {
  const [reason, setReason] = React.useState<string>('');

  const handleReject = () => {
    handleRejectOrder(selectedOrderIndex, reason);
    setReason('');
  };

  return (
    <>
      <div className="modal fade" id="rejectOrderModal" tabIndex={-1} role="dialog" aria-labelledby="rejectOrderModal" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="rejectOrderModalLabel">
                Odrzuć zamówienie
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="form-group">
                <label htmlFor="powod_odrzucenia">Podaj powód odrzucenia</label>
                <textarea
                  onChange={(e) => setReason(e.target.value)}
                  value={reason}
                  className="form-control"
                  id="powod_odrzucenia"
                  rows={3}></textarea>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="cancel-button btn btn-secondary" data-dismiss="modal">
                NIE
              </button>
              <button type="button" onClick={() => handleReject()} data-dismiss="modal" className="submit-button btn btn-primary">
                TAK, ODRZUĆ
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RejectionModalComponent;
