import * as yup from 'yup';

export const orderFormDetailsInitialState = {
  orderPerson: '',
  orderPersonEmail: '',
  recipientName: '',
  recipientPerson: '',
  recipientPhone: '',
  recipientEmail: '',
  recipientAddress: '',
  recipientCity: '',
  recipientPostalCode: ''
};

export const orderFormDetailsSchema = yup.object().shape({
  orderPerson: yup.string().required('Imie i nazwisko jest wymagane'),
  orderPersonEmail: yup.string().email('Wpisz poparwny adres e-mail').required('Osoba do kontaktu jest wymagana'),
  recipientName: yup.string().required('Nazwa odbiory jest wymagana'),
  recipientPerson: yup.string().required('Nazwa odbiory jest wymagana'),
  recipientPhone: yup.string().required('Numer kontaktowy jest wymagany'),
  recipientEmail: yup.string().email('Wpisz poparwny adres e-mail'),
  recipientAddress: yup.string().required('Adres jest wymagany'),
  recipientCity: yup.string().required('Miasto jest wymagane'),
  recipientPostalCode: yup.string().required('Kod pocztowy jest wymagany'),
});
