import * as React from 'react';
import { ReactComponent as IconInfo } from '../../assets/info-icon.svg';

interface TooltipType {
  text: string;
}

function TooltipComponent({ text }: TooltipType) {
  const [showTooltip, setShowTooltip] = React.useState<boolean>(false);
  return (
    <>
      <IconInfo onClick={() => setShowTooltip(!showTooltip)} className="action-icon reason-info" width={30} />
      {showTooltip && (
        <div className="tooltip-info-wrapper">
          <span>{text || 'brak informacji'}</span>
        </div>
      )}
    </>
  );
}

export default TooltipComponent;
