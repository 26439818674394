import React, { ReactElement } from 'react';
import { FieldErrors, FieldValues, UseFormRegister } from 'react-hook-form';
import { ReactComponent as IconInfo } from '../../assets/info-icon.svg';
import IconTooltipComponent from '../application/tooltip.componet';
import { GroupOrderData } from '../../store/types';
import { get as _get } from 'lodash';

interface GroupOrderNestedEmployeeDetailType {
  nestIndex: number;
  register: UseFormRegister<GroupOrderData>;
  errors: FieldErrors<FieldValues>;
}

export default ({ nestIndex, register, errors }: GroupOrderNestedEmployeeDetailType) => {
  const renderFormView = (): ReactElement => {
    return (
      <div className="row m-0 form-order-detail">
        <div className="col-12 section-info mb-1">
          <div className="section-info-text">DANE ZAMAWIAJĄCEGO</div>
        </div>
        <div className="col-6">
          <div className="form-group">
            <label htmlFor="orderPerson">Imię i nazwisko Zamawiającego</label>
            <input
              disabled
              {...register(`orders.${nestIndex}.orderPerson`)}
              type="text"
              className="form-control"
              placeholder="Podaj imię i nazwisko"
            />
            {_get(errors, `orders[${nestIndex}].orderPerson`) && (
              <span className="error-message">{_get(errors, `orders[${nestIndex}].orderPerson.message`)}</span>
            )}
          </div>
        </div>
        <div className="col-6">
          <div className="form-group">
            <label htmlFor="orderPersonEmail">Adres e-mail Zamawiającego</label>
            <input
              disabled
              {...register(`orders.${nestIndex}.orderPersonEmail`)}
              type="text"
              className="form-control"
              placeholder="Podaj adres mailowy"
            />
            {_get(errors, `orders[${nestIndex}].orderPersonEmail`) && (
              <span className="error-message">{_get(errors, `orders[${nestIndex}].orderPersonEmail.message`)}</span>
            )}
          </div>
        </div>
        <div className="col-12 section-info mt-3 mb-1">
          <div className="section-info-text">DANE DO WYSYŁKI ZAMÓWIENIA</div>
        </div>

        <div className="col-5">
          <div className="form-group">
            <label htmlFor="recipientName" className="d-flex align-items-center">
              Nazwa Odbiorcy{' '}
              <div className="ml-2 info-icon">
                <IconTooltipComponent
                  text="W tym miejscu wpisz nazwę odbiorcy, np. firma, instytuacja, szpital, apteka, dom, blok, numer piętra. Ta informacja, pozwoli kurierowi odnaleść odbiorcę paczki."
                  icon={<IconInfo width={20} />}
                />
              </div>
            </label>
            <input {...register(`orders.${nestIndex}.recipientName`)} type="text" className="form-control" placeholder="Nazwa firmy, instytucji" />
            {_get(errors, `orders[${nestIndex}].recipientName`) && (
              <span className="error-message">{_get(errors, `orders[${nestIndex}].recipientName.message`)}</span>
            )}
          </div>
        </div>
        <div className="col-4">
          <div className="form-group">
            <label htmlFor="recipientPerson">Imię i nazwisko Odbiorcy</label>
            <input {...register(`orders.${nestIndex}.recipientPerson`)} type="text" className="form-control" placeholder="Podaj osobę kontaktową" />
            {_get(errors, `orders[${nestIndex}].recipientPerson`) && (
              <span className="error-message">{_get(errors, `orders[${nestIndex}].recipientPerson.message`)}</span>
            )}
          </div>
        </div>
        <div className="col-3">
          <div className="form-group">
            <label htmlFor="recipientPhone">Nr. kontaktowy Odbiorcy zamówienia</label>
            <input {...register(`orders.${nestIndex}.recipientPhone`)} type="number" className="form-control" placeholder="Nr. kontaktowy Odbiorcy" />
            {_get(errors, `orders[${nestIndex}].recipientPhone`) && (
              <span className="error-message">{_get(errors, `orders[${nestIndex}].recipientPhone.message`)}</span>
            )}
          </div>
        </div>
        <div className="col-3">
          <div className="form-group">
            <label htmlFor="recipientEmail">Adres e-mail Odbiorcy</label>
            <input {...register(`orders.${nestIndex}.recipientEmail`)} type="text" className="form-control" placeholder="E-mail odbiorcy" />
            {_get(errors, `orders[${nestIndex}].recipientEmail`) && (
              <span className="error-message">{_get(errors, `orders[${nestIndex}].recipientEmail.message`)}</span>
            )}
          </div>
        </div>
        <div className="col-5">
          <div className="form-group">
            <label htmlFor="recipientAddress">Ulica, numer domu/mieszkania</label>
            <input
              {...register(`orders.${nestIndex}.recipientAddress`)}
              type="text"
              className="form-control"
              placeholder="Podaj pełen adres dostawy"
            />
            {_get(errors, `orders[${nestIndex}].recipientAddress`) && (
              <span className="error-message">{_get(errors, `orders[${nestIndex}].recipientAddress.message`)}</span>
            )}
          </div>
        </div>
        <div className="col-2">
          <div className="form-group">
            <label htmlFor="recipientCity">Miasto</label>
            <input {...register(`orders.${nestIndex}.recipientCity`)} type="text" className="form-control" placeholder="Podaj miasto" />
            {_get(errors, `orders[${nestIndex}].recipientCity`) && (
              <span className="error-message">{_get(errors, `orders[${nestIndex}].recipientCity.message`)}</span>
            )}
          </div>
        </div>
        <div className="col-2">
          <div className="form-group">
            <label htmlFor="recipientPostalCode">Kod pocztowy</label>
            <input {...register(`orders.${nestIndex}.recipientPostalCode`)} type="text" className="form-control" placeholder="Kod pocztowy" />
            {_get(errors, `orders[${nestIndex}].recipientPostalCode`) && (
              <span className="error-message">{_get(errors, `orders[${nestIndex}].recipientPostalCode.message`)}</span>
            )}
          </div>
        </div>
      </div>
    );
  };

  return <>{renderFormView()}</>;
};
