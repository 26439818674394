import * as React from 'react';
import _ from 'lodash';
import { useGetWarehouseHistoryQuery } from '../../services/warehouse.service';
import { API_HOST, AUTH_TOKEN, WarehouseHistoryData, WarehouseHistoryProduct, XlsxFileData } from '../../store/types';
import { ReactComponent as IconShow } from '../../assets/show-icon.svg';
import { ReactComponent as IconXslx } from '../../assets/xlsx-icon.svg';
import { ReactComponent as IconAccept } from '../../assets/accept-icon.svg';
import { ReactComponent as IconSend } from '../../assets/send-icon.svg';
import { ReactComponent as IconReject } from '../../assets/reject-icon.svg';
import SendOrderToUedModalComponent from './sendOrderToUedModal.component';
import { useSendOrderToUedEmailsMutation } from '../../services/order.service';
import { showToast } from '../../features/appSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { dateToString } from '../../utils/dateformat';

function HistoryComponent() {
  const { data: warehouseHistory } = useGetWarehouseHistoryQuery();
  const [sendOrderToUed] = useSendOrderToUedEmailsMutation();
  const [history, setHistory] = React.useState<WarehouseHistoryData[]>([]);
  const [orderIdForUedSend, setOrderIdForUedSend] = React.useState<number | null>(null);
  const loggedUserID = useAppSelector((state) => state.app.userID);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (warehouseHistory && !_.isEmpty(warehouseHistory.documents)) {
      setHistory(warehouseHistory.documents);
    }
  }, [warehouseHistory]);

  const renderHistoryDetail = (historyProducts: WarehouseHistoryProduct[]) => {
    if (historyProducts && !_.isEmpty(historyProducts)) {
      return historyProducts.map((history: WarehouseHistoryProduct, productIndex: number) => {
        const rowClass = productIndex % 2 === 0 ? 'white-row' : 'grey-row';

        return (
          <tr className={rowClass} key={`warehouse-history-detail-product-${productIndex}`}>
            <td>{productIndex + 1}</td>
            <td>{history.code}</td>
            <td>{history.productName}</td>
            <td>{history.valueBefore}</td>
            <td>{history.valueAfter}</td>
          </tr>
        );
      });
    }

    return (
      <tr>
        <td colSpan={5}>brak szczegółów historii do wyświetlenia</td>
      </tr>
    );
  };

  const handleSendOrderlToUed = async (uedEmails: string[]) => {
    // send request to UED here for lastAcceptedOrderId

    if (orderIdForUedSend) {
      const response = await sendOrderToUed({
        orderId: orderIdForUedSend?.toString(),
        emails: uedEmails
      }).unwrap();

      dispatch(
        showToast({
          show: true,
          type: response.success ? 'success' : 'error',
          message: response.success ? 'Zamówienie zostało wysłane do UED' : 'Wystąpił błąd, skontaktuj się z administratorem'
        })
      );
      updateHistoryUedFlag(orderIdForUedSend, uedEmails);
    }

    setOrderIdForUedSend(null);
  };

  const updateHistoryUedFlag = (orderId: number, uedEmails: string[]) => {
    let historyTmp: WarehouseHistoryData[] = [...history];

    historyTmp = historyTmp.map((history: WarehouseHistoryData) => {
      const historyObj = { ...history };
      if (historyObj.orderId === orderId) {
        historyObj.uedSend = true;
        historyObj.uedMails = uedEmails.join(',');
      }
      return historyObj;
    });

    setHistory(historyTmp);
  };

  const getXlsxFile = async (orderId: number, groupOrderId: number) => {
    let body: XlsxFileData = {};

    if (orderId && !groupOrderId) {
      body.orderId = orderId;
    } else if (groupOrderId) {
      body.groupOrderId = groupOrderId;
    }

    if (loggedUserID) {
      const response = await fetch(`${API_HOST}getXlsxOrderFile`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: AUTH_TOKEN,
          Userid: loggedUserID.toString()
        },
        body: JSON.stringify(body)
      });

      const blob = await response.blob();

      const url: string = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `zamowienie-${new Date().getTime()}.xlsx`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    }
  };

  const renderWArehouseHistory = () => {
    if (history && history.length) {
      return history.map((history: WarehouseHistoryData, index: number) => {
        const rowClass = index % 2 === 0 ? 'white-row' : 'grey-row';

        let uedPopup = null;

        if (history.uedSend) {
          uedPopup = (
            <div className="ued-mail-popup">
              {history.uedMails.split(',').map((mail: string) => {
                return <div>{mail}</div>;
              })}
            </div>
          );
        }

        return (
          <>
            <tr className={rowClass} key={`single-history-row-${index}`}>
              <td>{index + 1}</td>
              <td>{history.userName}</td>
              <td>{history.groupOrderPerson || history.userFromOrder || '-'}</td>
              <td>{history.recipientPerson ? history.recipientPerson : '-'}</td>
              <td>{dateToString(history.date)}</td>
              <td className="text-center">{history.products.length} produktów</td>
              <td className="text-center ued-info-wrapper">
                {history.uedSend && <IconAccept className="action-icon accept-order" width={24} />}
                {!history.uedSend && <IconReject className="action-icon accept-order" width={24} />}
                {uedPopup}
              </td>
              <td className="text-center ued-info-wrapper">
                {history.groupOrderId && <IconAccept className="action-icon accept-order" width={24} />}
                {!history.groupOrderId && <IconReject className="action-icon accept-order" width={24} />}
                {uedPopup}
              </td>
              <td>
                <div className="action-button-wrapper">
                  <div
                    data-toggle="collapse"
                    data-target={`#history-${index}-collapse`}
                    aria-expanded="false"
                    aria-controls={`history-${index}-collapse`}>
                    <IconShow className="action-icon show-order" width={30} />
                  </div>
                  {history.orderId && (
                    <div data-toggle="modal" onClick={() => setOrderIdForUedSend(history.orderId)} data-target="#sendOrderToUedModalInHistory">
                      <IconSend className="action-icon show-order" width={30} />
                    </div>
                  )}
                  {(history.orderId || history.groupOrderId) && (
                    <div onClick={() => getXlsxFile(history.orderId, history.groupOrderId)}>
                      <IconXslx className="action-icon" width={30} />
                    </div>
                  )}
                </div>
              </td>
            </tr>
            <tr key={`single-history-detail-row-${index}`}>
              <td className="order-details" colSpan={9}>
                <div className="collapse" id={`history-${index}-collapse`}>
                  <table className="table order-product-list-table">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Kod produktu</th>
                        <th scope="col">Nazwa produktu</th>
                        <th scope="col">Ilość przed</th>
                        <th scope="col">Ilość po</th>
                      </tr>
                    </thead>
                    <tbody>{renderHistoryDetail(history.products)}</tbody>
                  </table>
                </div>
              </td>
            </tr>
          </>
        );
      });
    }

    return (
      <tr>
        <td colSpan={9}>brak historii magazynowej do wyświetlenia</td>
      </tr>
    );
  };

  return (
    <>
      <table className="table history-table">
        <thead className="thead-dark">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Akceptujący</th>
            <th scope="col">Zamawiający</th>
            <th scope="col">Odbiorca</th>
            <th scope="col">Data</th>
            <th scope="col">Ilość produktów</th>
            <th scope="col">Czy wysłane do UED?</th>
            <th scope="col">Czy wysyłka grupowa?</th>
            <th scope="col">Akcja</th>
          </tr>
        </thead>
        <tbody>{renderWArehouseHistory()}</tbody>
      </table>
      <SendOrderToUedModalComponent handleSendOrderlToUed={handleSendOrderlToUed} modalHref="sendOrderToUedModalInHistory" />
    </>
  );
}

export default HistoryComponent;
