import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { showToast } from '../../features/appSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useForm } from 'react-hook-form';
import { useAddGroupMutation, useEditGroupMutation } from '../../services/group.service';
import { groupSchema, groupInitialState } from './schemas/group.schema';
import { GroupData } from '../../store/types';
import { resetEditedGroup } from '../../features/groupSlice';

interface EmployeeFormType {
  addGroupInputRef: any; //@TODO TYPE FOR REF
  isCollapsing: boolean;
  setIsCollapsing: Function;
  checkIfGroupAlreadyExist: (groupName: string) => boolean;
}

function GroupFormComponent({ addGroupInputRef, isCollapsing, setIsCollapsing, checkIfGroupAlreadyExist }: EmployeeFormType) {
  const editedGroupState = useAppSelector((state) => state.groups.editedGroup);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues
  } = useForm({
    resolver: yupResolver(groupSchema)
  });
  const dispatch = useAppDispatch();
  const [addGroup] = useAddGroupMutation();
  const [editGroup] = useEditGroupMutation();

  const id = getValues('id');

  React.useEffect(() => {
    if (editedGroupState) {
      reset({ ...editedGroupState });
    } else {
      reset({ ...groupInitialState });
    }
  }, [editedGroupState]);

  const onSubmit = handleSubmit(async (data: Partial<GroupData>) => {

    if (
      data &&
      data.name &&
      (!editedGroupState || (editedGroupState && editedGroupState.id && editedGroupState.id !== data.id)) &&
      checkIfGroupAlreadyExist(data.name.trim())
    ) {
      dispatch(
        showToast({
          show: true,
          type: 'error',
          message: `Grupa ${data.name} już istnieje na liście grup`
        })
      );
      return;
    }

    const response = await (id ? editGroup(data) : addGroup(data)).unwrap();
    window.scrollTo(0, 0);

    dispatch(
      showToast({
        show: true,
        type: response.success ? 'success' : 'error',
        message: response.success ? (id ? 'Grupa została zaktualizowana' : 'Dodano grupę') : 'Wystąpił błąd'
      })
    );

    if (response.success) {
      // close add region form
      if (addGroupInputRef.current) {
        addGroupInputRef.current.click();
      }

      dispatch(resetEditedGroup());
      reset({ ...groupInitialState });
    }
  });

  const handleCancelForm = () => {
    if (id) {
      dispatch(resetEditedGroup());
      reset({ ...groupInitialState });
    }

    if (addGroupInputRef.current) {
      addGroupInputRef.current.click();
    }
  };

  return (
    <>
      <div className="buttons-wrapper">
        <div
          ref={addGroupInputRef}
          onClick={() => setIsCollapsing(!isCollapsing)}
          data-toggle="collapse"
          data-target="#add-group-collapse"
          aria-expanded="false"
          aria-controls="add-group-collapse"
          className={`add-group-button ${isCollapsing ? 'isHide' : ''}`}>
          {editedGroupState ? `Edytuj grupę ${editedGroupState.name}` : 'Dodaj grupę'}
        </div>
      </div>
      <div className="collapse group-collapse" id="add-group-collapse">
        <div className="card card-body">
          <p>{editedGroupState ? `Edytuj grupę ${editedGroupState.name}` : 'Dodaj grupę'}</p>
          <form id="addNewGroup" onSubmit={onSubmit}>
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="name">Nazwa grupy</label>
                  <input {...register('name')} type="text" className="form-control" placeholder="podaj nazwę grupy" />
                  {errors.name && <span className="error-message">{errors.name.message}</span>}
                </div>
              </div>
              <div className="col-12 submit-wrapper">
                <button onClick={handleCancelForm} type="button" className="cancel-button btn btn-secondary">
                  Anuluj
                </button>
                <button type="submit" className="submit-button btn btn-primary">
                  {id ? 'Edytuj grupę' : 'Dodaj grupę'}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default GroupFormComponent;
