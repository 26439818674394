import * as React from 'react';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../store/hooks';
import { PermissionType } from '../../store/types';
import { ReactComponent as UserModuleIcon } from '../../assets/dostawa.svg';
import { ReactComponent as AdminModuleIcon } from '../../assets/magazyn.svg';
import { includes as _includes } from 'lodash';

function HomeComponent() {
  const [loggedUserType, setLoggedUserType] = React.useState<string>('');
  const loggedUserState = useAppSelector((state) => state.app.loggedUser);

  React.useEffect(() => {
    if (loggedUserState && loggedUserState.wmsPermissionType) {
      setLoggedUserType(loggedUserState.wmsPermissionType);
    }
  }, [loggedUserState]);

  return (
    <>
      {loggedUserState && (
        <div className="row app_row">
          {_includes([PermissionType.USER_TYPE_USER, PermissionType.USER_TYPE_ADMIN, PermissionType.USER_TYPE_ADMIN], loggedUserType) && (
            <div className="col-xl-6 col-sm-6 col-6">
              <Link className="app_box ease" to="/ordering">
                <UserModuleIcon width={200} />
                <div className="app_box_appla"></div>
              </Link>
            </div>
          )}
          {_includes([PermissionType.USER_TYPE_ADMIN], loggedUserType) && (
            <div className="col-xl-6 col-sm-6 col-6">
              <Link className="app_box ease" to="/administration">
                <AdminModuleIcon width={120} />
                <div className="app_box_appla"></div>
              </Link>
            </div>
          )}
          {_includes([PermissionType.USER_TYPE_NONE], loggedUserType) && (
            <div className="col-xl-12 col-sm-12 col-12 text-center mt-5 mb-5">
              <h5>Brak dostępu. Skontaktuj się z administratorem.</h5>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default HomeComponent;
