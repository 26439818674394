import React, { ReactElement, useEffect } from 'react';
import { FieldErrors, FieldValues, UseFormRegister } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { ReactComponent as IconInfo } from '../../assets/info-icon.svg';
import { OrderDetailFormData } from '../../store/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { orderFormDetailsSchema } from './schemas/orderFormDetailsSchema';
import IconTooltipComponent from './tooltip.componet';

interface OrderFormDetailsType {
  registerData?: UseFormRegister<any>;
  errorsData?: FieldErrors<OrderDetailFormData>;
  orderId?: string;
  initialData?: OrderDetailFormData;
  onChange?: (orderId: string, data: OrderDetailFormData) => void;
  setOrderIsValid?: (orderId: string, isValid: boolean) => void;
  orderPersonNameIsDisabled?: boolean;
}

function OrderFormDetailsComponent({
  registerData,
  errorsData,
  orderId,
  initialData,
  onChange,
  setOrderIsValid,
  orderPersonNameIsDisabled = true
}: OrderFormDetailsType) {
  const {
    register,
    reset,
    setValue,
    handleSubmit,
    getValues,
    formState: { errors }
  } = useForm<OrderDetailFormData>({
    shouldFocusError: false,
    resolver: yupResolver(orderFormDetailsSchema)
  });

  const [currentOrderIsValid, setCurrentOrderIsvalid] = React.useState<boolean>(true);

  let formErrors: FieldErrors<OrderDetailFormData> = errorsData ? errorsData : errors;
  let formRegister: UseFormRegister<OrderDetailFormData> = registerData ? registerData : register;

  useEffect(() => {
    if (setOrderIsValid && orderId) {
      setOrderIsValid(orderId, currentOrderIsValid);
    }
  }, [currentOrderIsValid]);

  useEffect(() => {
    if (initialData) {
      reset({ ...initialData });
    }
  }, [initialData]);

  useEffect(() => {
    if (orderId !== undefined && setOrderIsValid) {
      if (formErrors && Object.keys(formErrors).length && currentOrderIsValid) {
        setCurrentOrderIsvalid(false);
      } else if (formErrors && !Object.keys(formErrors).length && !currentOrderIsValid) {
        setCurrentOrderIsvalid(true);
      }
    }
  }, [errors]);

  useEffect(() => {
    if (registerData && !errorsData) {
      throw new Error('Error data required');
    }
    if (!registerData && errorsData) {
      throw new Error('Register data required');
    }

    if (initialData && orderId === undefined) {
      throw new Error('Order ID required');
    }

    if (!initialData && orderId !== undefined) {
      throw new Error('Initial data required');
    }
  }, []);

  const handleOnSubmit = (data: OrderDetailFormData): void => {
    if (onChange && orderId) {
      onChange(orderId, { ...data });
    }
  };

  const renderForm = (): ReactElement => {
    if (initialData && !registerData) {
      return <form onChange={handleSubmit(handleOnSubmit)}>{renderFormView()}</form>;
    }

    return renderFormView();
  };

  const renderFormView = (): ReactElement => {
    return (
      <div className="row m-0 form-order-detail">
        <div className="col-12 section-info mb-1">
          <div className="section-info-text">DANE ZAMAWIAJĄCEGO</div>
        </div>
        <div className="col-6">
          <div className="form-group">
            <label htmlFor="orderPerson">Imię i nazwisko Zamawiającego</label>
            <input
              disabled={orderPersonNameIsDisabled}
              {...formRegister('orderPerson')}
              type="text"
              className="form-control"
              placeholder="Podaj imię i nazwisko"
            />
            {formErrors.orderPerson && <span className="error-message">{formErrors.orderPerson.message}</span>}
          </div>
        </div>
        <div className="col-6">
          <div className="form-group">
            <label htmlFor="orderPersonEmail">Adres e-mail Zamawiającego</label>
            <input {...formRegister('orderPersonEmail')} type="text" className="form-control" placeholder="Podaj adres mailowy" />
            {formErrors.orderPersonEmail && <span className="error-message">{formErrors.orderPersonEmail.message}</span>}
          </div>
        </div>
        <div className="col-12 section-info mt-3 mb-1">
          <div className="section-info-text">DANE DO WYSYŁKI ZAMÓWIENIA</div>
        </div>

        <div className="col-5">
          <div className="form-group">
            <label htmlFor="recipientName" className="d-flex align-items-center">
              Nazwa Odbiorcy{' '}
              <div className="ml-2 info-icon">
                <IconTooltipComponent
                  text="W tym miejscu wpisz nazwę odbiorcy, np. firma, instytuacja, szpital, apteka, dom, blok, numer piętra. Ta informacja, pozwoli kurierowi odnaleść odbiorcę paczki."
                  icon={<IconInfo width={20} />}
                />
              </div>
            </label>
            <input {...formRegister('recipientName')} type="text" className="form-control" placeholder="Nazwa firmy, instytucji" />
            {formErrors.recipientName && <span className="error-message">{formErrors.recipientName.message}</span>}
          </div>
        </div>
        <div className="col-4">
          <div className="form-group">
            <label htmlFor="recipientPerson">Imię i nazwisko Odbiorcy</label>
            <input {...formRegister('recipientPerson')} type="text" className="form-control" placeholder="Podaj osobę kontaktową" />
            {formErrors.recipientPerson && <span className="error-message">{formErrors.recipientPerson.message}</span>}
          </div>
        </div>
        <div className="col-3">
          <div className="form-group">
            <label htmlFor="recipientPhone">Nr. kontaktowy Odbiorcy zamówienia</label>
            <input {...formRegister('recipientPhone')} type="number" className="form-control" placeholder="Nr. kontaktowy Odbiorcy" />
            {formErrors.recipientPhone && <span className="error-message">{formErrors.recipientPhone.message}</span>}
          </div>
        </div>
        <div className="col-3">
          <div className="form-group">
            <label htmlFor="recipientEmail">Adres e-mail Odbiorcy</label>
            <input {...formRegister('recipientEmail')} type="text" className="form-control" placeholder="E-mail odbiorcy" />
            {formErrors.recipientEmail && <span className="error-message">{formErrors.recipientEmail.message}</span>}
          </div>
        </div>
        <div className="col-5">
          <div className="form-group">
            <label htmlFor="recipientAddress">Ulica, numer domu/mieszkania</label>
            <input {...formRegister('recipientAddress')} type="text" className="form-control" placeholder="Podaj pełen adres dostawy" />
            {formErrors.recipientAddress && <span className="error-message">{formErrors.recipientAddress.message}</span>}
          </div>
        </div>
        <div className="col-2">
          <div className="form-group">
            <label htmlFor="recipientCity">Miasto</label>
            <input {...formRegister('recipientCity')} type="text" className="form-control" placeholder="Podaj miasto" />
            {formErrors.recipientCity && <span className="error-message">{formErrors.recipientCity.message}</span>}
          </div>
        </div>
        <div className="col-2">
          <div className="form-group">
            <label htmlFor="recipientPostalCode">Kod pocztowy</label>
            <input {...formRegister('recipientPostalCode')} type="text" className="form-control" placeholder="Kod pocztowy" />
            {formErrors.recipientPostalCode && <span className="error-message">{formErrors.recipientPostalCode.message}</span>}
          </div>
        </div>
      </div>
    );
  };

  return <>{renderForm()}</>;
}

export default OrderFormDetailsComponent;
