import * as React from 'react';
import { API_HOST } from '../../store/types';
import { ReactComponent as IconGallery } from '../../assets/gallery-icon.svg';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

interface GalleryType {
  photos: string[];
  productIndex: number;
}

interface GalleryImage {
  original: string;
  thumbnail: string;
  thumbnailHeight?: number;
  thumbnailWidth?: number;
  originalHeight?: number;
  originalWidth?: number;
}

function GalleryComponent({ photos, productIndex }: GalleryType) {
  const [photosExist, setPhotosExist] = React.useState<boolean>(false);
  const [galleryPhotos, setGalleryPhotos] = React.useState<GalleryImage[]>([]);

  React.useEffect(() => {
    if (photos && photos.length) {
      const falleryPhotosTmp: GalleryImage[] = [];

      photos.map((photo: string) => {
        falleryPhotosTmp.push({
          thumbnail: `${API_HOST}${photo}`,
          original: `${API_HOST}${photo}`,
          originalHeight: 500
        });
      });

      setPhotosExist(true);
      setGalleryPhotos(falleryPhotosTmp);
    } else {
      setPhotosExist(false);
    }
  }, [photos]);

  return (
    <div>
      <span>
        {photosExist ? (
          <div data-toggle="modal" data-target={`#galleryModal_${productIndex}`}>
            <IconGallery width={24} />
          </div>
        ) : (
          'brak'
        )}
      </span>
      <div
        className="ued-modal modal fade"
        id={`galleryModal_${productIndex}`}
        tabIndex={-1}
        role="dialog"
        aria-labelledby={`galleryModal_${productIndex}`}
        aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id={`productGallery${productIndex}`}>
                Zdjęcia produktowe
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-12">
                  <ImageGallery showThumbnails={false} showFullscreenButton={false} items={galleryPhotos} showPlayButton={photos.length > 1} />
                </div>
                <div className="col-12 d-flex justify-content-end">
                  <button type="button" className="cancel-button btn btn-secondary mr-2" data-dismiss="modal">
                    ZAMKNIJ
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GalleryComponent;
