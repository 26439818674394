import * as React from 'react';

interface AcceptanceModalType {
  handleAcceptanceOrder: Function;
  selectedOrderIndex: number | null;
}

function AcceptanceModalComponent({ handleAcceptanceOrder, selectedOrderIndex }: AcceptanceModalType) {
  return (
    <>
      <div className="modal fade" id="acceptOrderModal" tabIndex={-1} role="dialog" aria-labelledby="acceptOrderModal" aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="acceptOrderModalLabel">
                Zaakceptuj zamówienie
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">Czy na pewno chcesz zaakceptować to zamówienie?</div>
            <div className="modal-footer">
              <button type="button" className="cancel-button btn btn-secondary" data-dismiss="modal">
                NIE
              </button>
              <button
                type="button"
                onClick={() => handleAcceptanceOrder(selectedOrderIndex)}
                data-dismiss="modal"
                className="submit-button btn btn-primary">
                TAK, ZATWIERDŹ
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AcceptanceModalComponent;
