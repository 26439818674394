import * as React from 'react';
import { useAppSelector } from '../../store/hooks';
import SelectComponent, { SelectOption } from './select.component';
import { GroupAddProductToOrder, WarehouseProductData } from '../../store/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { groupAddProductSchema, groupAddProductInitialState } from './schemas/groupAddProduct.schema';

interface GroupProductModalType {
  handleSelectedProduct: (productId: number, amount: number) => void;
  modalHref: string;
  description: string;
}

function GroupProductModal({ handleSelectedProduct, modalHref, description }: GroupProductModalType) {
  const closeModalRef = React.useRef<any>();
  const [productOptions, setProductOptions] = React.useState<SelectOption[]>([]);
  const allProducts = useAppSelector((state) => state.groups.productsWithStock);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<GroupAddProductToOrder>({
    resolver: yupResolver(groupAddProductSchema)
  });

  React.useEffect(() => {
    reset({ ...groupAddProductInitialState });
  }, []);

  React.useEffect(() => {
    if (allProducts && allProducts.length) {
      const optionsTmp: SelectOption[] = [];

      allProducts.map((product: WarehouseProductData) => {
        if (product.id) {
          optionsTmp.push({ id: product.productId, value: `${product.code} - ${product.productName} (${product.amount} szt.)` });
        }
      });

      setProductOptions(optionsTmp);
    }
  }, [allProducts]);

  const onSubmit = handleSubmit(async (data: GroupAddProductToOrder) => {
    if (data.productId && data.amount) {
      handleSelectedProduct(parseInt(data.productId), data.amount);
      reset({ ...groupAddProductInitialState });
    }
    closeModalRef.current.click();
  });

  return (
    <>
      <div className="ued-modal modal fade" id={modalHref} tabIndex={-1} role="dialog" aria-labelledby={modalHref} aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addGroupProductToOrder">
                Wybierz produkt
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <span>{description}</span>
              <form className="mt-3" id="groupAddUserFrom" onSubmit={onSubmit}>
                <div className="row">
                  <div className="col-8">
                    <SelectComponent register={register} fieldName="productId" label="Produkt" errors={errors} optionData={productOptions} />
                  </div>

                  <div className="col-4">
                    <div className="form-group">
                      <label htmlFor="amount">Ilość.</label>
                      <input {...register('amount')} type="number" className="form-control" placeholder="np. 50 szt." />
                      {errors.amount && <span className="error-message">{errors.amount.message}</span>}
                    </div>
                  </div>

                  <div className="col-12 d-flex justify-content-end">
                    <button type="button" ref={closeModalRef} data-dismiss="modal" className="submit-button hidden-button btn btn-primary"></button>
                    <button type="button" className="cancel-button btn btn-secondary mr-2" data-dismiss="modal">
                      NIE
                    </button>
                    <button type="submit" onClick={() => {}} className="submit-button btn btn-primary">
                      TAK, DODAJ
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GroupProductModal;
