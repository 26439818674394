import React, { useEffect } from 'react';
import { useForm, useFieldArray, useWatch } from 'react-hook-form';
import { useAddGroupOrderMutation, useGetEmployeesQuery, useSendGroupOrderToUedEmailsMutation } from '../../services/group.service';
import { yupResolver } from '@hookform/resolvers/yup';
import { groupOrderInitialState, groupOrderSchema } from './schemas/groupOrder.schema';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { get as _get, findIndex as _findIndex } from 'lodash';
import { setEmployees, setProductWithStock, setProductsStockSum } from '../../features/groupSlice';
import { EmployeeData, GroupData, GroupOrder, GroupOrderData, GroupOrderProduct, OrderDetailFormData, WarehouseProductData } from '../../store/types';

import { useGetWarehouseProductsQuery } from '../../services/warehouse.service';
import GroupUserModal from './groupUserModal.component';
import GroupGroupModal from './groupGroupModal.component';
import GroupProductModal from './groupProductModal.component';
import { ReactComponent as IconReject } from '../../assets/reject-icon.svg';
import { ReactComponent as IconInfo } from '../../assets/info-icon.svg';
import GroupOrderNestedProducts from './groupOrderNestedProducts.component';
import { showToast } from '../../features/appSlice';
import SendOrderToUedModalComponent from './sendOrderToUedModal.component';
import GroupOrderNestedEmployeeDetailComponent from './groupOrderNestedEmployeeDetail.component';

function GroupOrderComponent() {
  const dispatch = useAppDispatch();
  const [addGroupOrder] = useAddGroupOrderMutation();
  const [sendGroupOrderToUed] = useSendGroupOrderToUedEmailsMutation();
  const { data: employeeData } = useGetEmployeesQuery();
  const { data: warehouseData, refetch: refetchWarehouseProducts } = useGetWarehouseProductsQuery();
  const loggedUser = useAppSelector((state) => state.app.loggedUser);
  const allEmployees = useAppSelector((state) => state.groups.employees);
  const productsStockSum = useAppSelector((state) => state.groups.productsStockSum);
  const productsWithStock = useAppSelector((state) => state.groups.productsWithStock);
  const allGroups = useAppSelector((state) => state.groups.groups);
  const openUedModalRef = React.useRef<any>();
  const [lastInsertedGroupOrderId, setLastInsertedOrderId] = React.useState<number | null>(24);

  const {
    handleSubmit,
    formState: { errors },
    getValues,
    register,
    reset,
    clearErrors,
    setValue,
    setError,
    watch,
    control
  } = useForm<GroupOrderData>({
    resolver: yupResolver(groupOrderSchema)
  });

  const currentOrders = getValues('orders');
  const orderChanges = useWatch({ control, name: 'orders' });
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'orders'
  });

  React.useEffect(() => {
    if (productsWithStock.length) {
      validProductsAmount(orderChanges);
    }
  }, [productsWithStock]);

  React.useEffect(() => {
    handleOrderChanges(orderChanges);
  }, [orderChanges]);

  React.useEffect(() => {
    if (employeeData && _get(employeeData, 'documents', []).length) {
      dispatch(setEmployees(employeeData.documents));
    } else {
      dispatch(setEmployees([]));
    }
  }, [employeeData]);

  React.useEffect(() => {
    if (warehouseData && _get(warehouseData, 'documents', []).length) {
      dispatch(setProductWithStock(warehouseData.documents));
    }
  }, [warehouseData]);

  useEffect(() => {
    if (errors && Object.keys(errors).length > 0) {
      const errorsElements: HTMLCollectionOf<Element> = document.getElementsByClassName('error-message');
      if (errorsElements && errorsElements.length) {
        errorsElements[0].scrollIntoView({ block: 'end', behavior: 'smooth' });
      }
    }
  }, [errors]);

  const handleOrderChanges = (orderChanges: GroupOrder[]) => {
    if (orderChanges && orderChanges.length) {
      let allProductsFromOrder: GroupOrderProduct[] = [];

      orderChanges.map((order: GroupOrder, orderIndex: number) => {
        clearErrors(`orders.${orderIndex}.products`);

        order.products.map((product: GroupOrderProduct, productIndex: number) => {
          if (product && product.productId) {
            const productIndex = _findIndex(allProductsFromOrder, { productId: product.productId });

            if (productIndex !== -1 && allProductsFromOrder[productIndex].amount && product.amount) {
              const sum = allProductsFromOrder[productIndex].amount + product.amount;

              // const productAmountInWarehouse: WarehouseProductData | undefined = productsWithStock.find(
              //   (productSumObj: WarehouseProductData) => productSumObj.productId === product.productId
              // );

              // if (productAmountInWarehouse && parseInt(productAmountInWarehouse.amount) - sum >= 0) {
              //   clearErrors(`orders.${orderIndex}.products.${productIndex}.amount`);
              // }

              allProductsFromOrder[productIndex].amount = sum;
            } else if (product.amount) {
              allProductsFromOrder.push({ ...product });
            }
          }
        });
      });

      if (allProductsFromOrder.length) {
        dispatch(setProductsStockSum(allProductsFromOrder));
      }
    } else {
      dispatch(setProductsStockSum([]));
    }
  };

  const onSubmit = handleSubmit(async (data: GroupOrderData) => {
    try {
      clearErrors();
      if (!validProductsAmount(data.orders)) {
        return false;
      }

      const response = await addGroupOrder(data).unwrap();

      if (response.success) {
        reset({ ...groupOrderInitialState });

        if (response.groupOrderId) {
          setLastInsertedOrderId(response.groupOrderId);
        }

        dispatch(
          showToast({
            show: true,
            type: 'success',
            message: 'Zamówienie grupowe zostało dodane do systemu'
          })
        );

        openUedModalRef.current.click();
      }
    } catch (response: any) {
      const error: string = _get(response, 'data.errors.message', '');

      if (error === 'stock-is-full-for-some-products') {
        refetchWarehouseProducts();
        dispatch(
          showToast({
            show: true,
            type: 'error',
            message: 'Brak wybranych produktów na magazynie, sprawdź zamówienie.'
          })
        );
      }
    }
  });

  const handleSendOrderlToUed = async (uedEmails: string[]): Promise<void> => {
    // send request to UED here for lastInsertedGroupOrderId
    if (lastInsertedGroupOrderId) {
      const response = await sendGroupOrderToUed({
        groupOrderId: lastInsertedGroupOrderId,
        emails: uedEmails
      }).unwrap();
      dispatch(
        showToast({
          show: true,
          type: response.success ? 'success' : 'error',
          message: response.success ? 'Zamówienie zostało wysłane do UED' : 'Wystąpił błąd, skontaktuj się z administratorem'
        })
      );
      setLastInsertedOrderId(null);
    }
  };

  const validProductsAmount = (orderData: GroupOrder[]): boolean => {
    let isValid = true;

    if (orderData && orderData.length) {
      orderData.map((order: GroupOrder, orderIndex: number) => {
        clearErrors(`orders.${orderIndex}.products`);

        order.products.map((product: GroupOrderProduct, productIndex: number) => {
          const sumForProductsInAllOrders: GroupOrderProduct | undefined = productsStockSum.find(
            (productSumObj: GroupOrderProduct) => productSumObj.productId === product.productId
          );

          const productAmountInWarehouse: WarehouseProductData | undefined = productsWithStock.find(
            (productSumObj: WarehouseProductData) => productSumObj.productId === product.productId
          );

          if (sumForProductsInAllOrders && productAmountInWarehouse) {
            const amountFromOrders = sumForProductsInAllOrders.amount;
            const amountFromWarehouse = productAmountInWarehouse.amountWithReservation;

            if (amountFromWarehouse - amountFromOrders < 0) {
              setError(`orders.${orderIndex}.products.${productIndex}.amount`, {
                type: 'custom',
                message: 'Wybrana ilość jest większa niż magazynowa'
              });
              isValid = false;
            }
          }
        });
      });
    }

    return isValid;
  };

  const handleSelectedUser = (employeeId: number): void => {
    refetchWarehouseProducts();
    checkIfEmployeeExistAndAdd(employeeId);
  };

  const handleSelectedGroup = (groupId: number): void => {
    refetchWarehouseProducts();

    // group id 0 is ALL USERS, then add all users
    const employeesFromGroup = allEmployees.filter((employee: EmployeeData) => (groupId === 0 ? true : employee.groupId === groupId));

    if (employeesFromGroup.length) {
      employeesFromGroup.map((employee: EmployeeData) => {
        if (employee.id) {
          checkIfEmployeeExistAndAdd(employee.id);
        }
      });
    }
  };

  const checkIfEmployeeExistAndAdd = (employeeId: number) => {
    const userAlreadyExist = currentOrders.find((order: GroupOrder) => order.recipientId === employeeId);

    if (!userAlreadyExist) {
      const selectedEmployee = allEmployees.find((employee: EmployeeData) => employee.id === employeeId);

      if (selectedEmployee && selectedEmployee.id) {
        const employee = { ...selectedEmployee };
        const userName = `${selectedEmployee.firstName} ${selectedEmployee.lastName}`;

        append({
          products: [],
          // orderPerson: userName,
          // orderPersonEmail: employee.email,
          orderPerson: loggedUser ? loggedUser.display_name : userName,
          orderPersonEmail: loggedUser ? loggedUser.user_email : employee.email,
          recipientName: userName,
          recipientPerson: userName,
          recipientPhone: employee.phone,
          recipientEmail: employee.email,
          recipientAddress: `${employee.street} ${employee.streetNumber}`,
          recipientCity: employee.city,
          recipientPostalCode: employee.postalCode,
          recipientId: employee.id || 0,
          isExpanded: false
        });
      }
    }
  };

  const handleSelectedProduct = (productId: number, amount: number): void => {
    if (fields && fields.length) {
      fields.map((field: GroupOrder, orderIndex: number) => {
        let orderCurrentProducts = [...currentOrders[orderIndex].products];

        // if order no have products, add one
        if (!orderCurrentProducts.length) {
          setValue(`orders.${orderIndex}.products`, [
            {
              productId: productId,
              amount: amount
            }
          ]);
        } else {
          // if order have products
          const productExist = orderCurrentProducts.find((product: GroupOrderProduct) => product.productId === productId);

          // if order have slected product, sum ammount
          if (productExist) {
            const productIndex = _findIndex(orderCurrentProducts, { productId: productId });
            setValue(`orders.${orderIndex}.products.${productIndex}.amount`, orderCurrentProducts[productIndex].amount + amount);
          } else {
            // if order have products but not have selected product, add this product
            setValue(`orders.${orderIndex}.products`, [
              ...orderCurrentProducts,
              {
                productId: productId,
                amount: amount
              }
            ]);
          }
        }
      });
    }
  };

  const setIsOrderDetailsIsExpanded = (orderIndex: number, field: GroupOrder): void => {
    update(orderIndex, {
      ..._get(orderChanges, `[${orderIndex}]`),
      isExpanded: !field.isExpanded
    });
  };

  const renderSubOrders = () => {
    if (!fields.length) {
      return <div className="d-flex justify-content-center mt-4 mb-4">Wybierz pracowników do wysyłki grupowej</div>;
    }

    return fields.map((field: GroupOrder, orderIndex: number) => {
      const bottomMargin = orderIndex > 0 ? 'mt-4' : '';
      const productErrorMessage = _get(errors, `orders[${orderIndex}].products.message`) ? (
        <tr>
          <td colSpan={8}>
            <div className="error-message">{_get(errors, `orders[${orderIndex}].products.message`)}</div>
          </td>
        </tr>
      ) : null;

      const recipientName: string = _get(orderChanges, `[${orderIndex}].recipientName`);
      const recipientAddress: string = _get(orderChanges, `[${orderIndex}].recipientAddress`);
      const recipientPostalCode: string = _get(orderChanges, `[${orderIndex}].recipientPostalCode`);
      const recipientCity: string = _get(orderChanges, `[${orderIndex}].recipientCity`);
      const recipientId: number = _get(orderChanges, `[${orderIndex}].recipientId`);
      const recipientGroupId: number | undefined = allEmployees.find((employee: EmployeeData) => employee.id === recipientId)?.groupId;
      let recipientPhone: string = _get(orderChanges, `[${orderIndex}].recipientPhone`);
      let recipientGroupName: string = '';

      if (recipientPhone) {
        recipientPhone = recipientPhone.replace(/^(\d{3})(\d{3})(\d{3})/, '$1-$2-$3');
      }

      if (recipientGroupId) {
        recipientGroupName = allGroups.find((group: GroupData) => group.id === recipientGroupId)?.name || '';
      }

      return (
        <div className={`group-order-single-user order-details ${bottomMargin}`} key={`single-order-user-${orderIndex}`}>
          <div className="p-3 d-flex align-items-center justify-content-between group-single-user-info">
            <div>
              <h6>
                <strong>#{orderIndex + 1} </strong>Odbiorca: {recipientName}{' '}
                <span>({`${recipientAddress}, ${recipientPostalCode} ${recipientCity}, ${recipientPhone}`})</span> [{recipientGroupName}]
              </h6>
            </div>
            <div className="d-flex group-order-single-user-action-button-wrapper">
              <div className="mr-2 user-info-icon" onClick={() => setIsOrderDetailsIsExpanded(orderIndex, field)}>
                <IconInfo width={29} height={29} />
              </div>
              <div onClick={() => remove(orderIndex)}>
                <IconReject className="action-icon reject-order" width={24} />
              </div>
            </div>
          </div>
          <div className={`group-single-user-info-detail mt-3 ${field.isExpanded ? '' : 'hidden-section'}`}>
            <GroupOrderNestedEmployeeDetailComponent register={register} errors={errors} nestIndex={orderIndex} />
          </div>
          <table className="table group-order-single-user-products m-0">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">kod produktu</th>
                <th scope="col">nazwa produktu</th>
                <th scope="col">Ilość w zamówieniu</th>
                <th scope="col">Ilość na magazynie</th>
                <th scope="col">Akcja</th>
              </tr>
            </thead>
            <tbody>
              {productErrorMessage}
              <GroupOrderNestedProducts nestIndex={orderIndex} {...{ control, register, errors, watch }} employeeName={field.recipientName} />
            </tbody>
          </table>
        </div>
      );
    });
  };

  return (
    <>
      <form id="addNewGroup" onSubmit={onSubmit}>
        <div className="order-buttons-section justify-content-end d-flex">
          {!!fields.length && (
            <button type="submit" className="submit-button btn btn-primary mr-3 green-button">
              Złóż zamówienie
            </button>
          )}
          <button type="button" className="submit-button btn btn-primary mr-3" data-toggle="modal" data-target="#groupUserModal">
            Dodaj pracownika
          </button>
          <button
            type="button"
            className={`submit-button btn btn-primary ${fields.length ? 'mr-3' : ''}`}
            data-toggle="modal"
            data-target="#groupGroupModal">
            Dodaj pracowników z grupy
          </button>
          {!!fields.length && (
            <button type="button" className="submit-button btn btn-primary" data-toggle="modal" data-target="#groupProductModal">
              Dodaj produkt dla wszystkich
            </button>
          )}
        </div>
        <div className="group-order-form-wrapper">{renderSubOrders()}</div>
      </form>
      <div className="hidden-button" data-toggle="modal" ref={openUedModalRef} data-target="#sendGroupOrderToUedModal"></div>
      <GroupUserModal handleSelectedUser={handleSelectedUser} modalHref="groupUserModal" />
      <GroupGroupModal handleSelectedGroup={handleSelectedGroup} modalHref="groupGroupModal" />
      <GroupProductModal
        description="Wybierz produkt który chcesz dodać do listy wszystkich pracowników."
        handleSelectedProduct={handleSelectedProduct}
        modalHref="groupProductModal"
      />
      <SendOrderToUedModalComponent handleSendOrderlToUed={handleSendOrderlToUed} modalHref="sendGroupOrderToUedModal" />
    </>
  );
}

export default GroupOrderComponent;
