import * as React from 'react';
import { API_HOST, ProductData } from '../../store/types';
import { useGetProductsQuery } from '../../services/product.service';
import ProductFormComponent from './productForm.component';
import _ from 'lodash';
import { ReactComponent as IconEdit } from '../../assets/edit-icon.svg';
import { ReactComponent as IconInfo } from '../../assets/info-icon.svg';
import { useAppDispatch } from '../../store/hooks';
import { setEditedProduct } from '../../features/productSlice';
import ImageComponent from '../application/image.component';
import GalleryComponent from '../application/gallery.component';

function ProductsComponent() {
  const dispatch = useAppDispatch();

  const [query, setQuery] = React.useState<string>('');
  const [isCollapsing, setIsCollapsing] = React.useState<boolean>(false);
  const { data: productData } = useGetProductsQuery();
  const addProductInputRef = React.useRef<any>(null); //@TODO TYPE FOR REF

  const handleEditProduct = (productCode: string) => {
    if (productData && _.get(productData, 'documents', []).length) {
      const editedProduct: ProductData | undefined = productData.documents.find((product: ProductData) => product.code === productCode);

      if (editedProduct) {
        dispatch(setEditedProduct(editedProduct));

        if (addProductInputRef.current && !isCollapsing) {
          addProductInputRef.current.click();
        }
        window.scrollTo(0, 0);
      }
    }
  };

  const checkIfProductAlreadyExist = (productCode: string) => {
    if (productData && !_.isEmpty(productData.documents)) {
      let allProducts: ProductData[] = productData.documents;
      return !!allProducts.find((product: ProductData) => product.code === productCode);
    }

    return false;
  };

  const renderProductList = () => {
    if (productData && !_.isEmpty(productData.documents)) {
      let allProducts: ProductData[] = productData.documents;

      if (query) {
        allProducts = allProducts.filter((product: ProductData) => {
          let finded = false;

          if (product.productName.match(new RegExp(query, 'gi'))) {
            finded = true;
          }

          if (product.categoryName.match(new RegExp(query, 'gi'))) {
            finded = true;
          }

          if (product.code.match(new RegExp(query, 'gi'))) {
            finded = true;
          }

          if (product.subProductName.match(new RegExp(query, 'gi'))) {
            finded = true;
          }

          return finded;
        });
      }

      return allProducts.map((product: ProductData, index: number) => {
        const rowClass = index % 2 === 0 ? 'white-row' : 'grey-row';

        const photoPaths: string[] = [];

        if (product.photo1) {
          photoPaths.push(product.photo1);
        }

        if (product.photo2) {
          photoPaths.push(product.photo2);
        }

        if (product.photo3) {
          photoPaths.push(product.photo3);
        }

        return (
          <tr className={rowClass} key={`single-prod-row-${index}`}>
            <td>{index + 1}</td>
            {/* <td className="p-relative">
              {Boolean(product.photo.length) && <img alt="" className="product-img" width="50px" height="50px" src={`${API_HOST}${product.photo}`} />}
              {Boolean(product.photo.length) && (
                <img alt="" className="product-img-hidden" width="50px" height="50px" src={`${API_HOST}${product.photo}`} />
              )}
              {Boolean(!product.photo.length) && <span>brak</span>}
            </td> */}
            <td className="p-relative">
              <GalleryComponent photos={photoPaths} productIndex={index} />
            </td>
            <td>
              {product.link ? (
                <div className="link-wrapper" onClick={() => window.open(product.link, '_blank', 'noreferrer')}>
                  <IconInfo width={24} />{' '}
                </div>
              ) : (
                'brak'
              )}
            </td>
            {/* <td>Zdjęcie</td> */}
            <td>{product.code}</td>
            <td>{product.clientCode}</td>
            <td>{product.productName}</td>
            <td>{product.subProductName}</td>
            <td>{product.categoryName}</td>
            <td>{product.unit}</td>
            <td className="text-center">{product.amount}</td>
            <td className="text-center">{product.price} zł</td>
            <td>
              <div onClick={() => handleEditProduct(product.code)}>
                <IconEdit width={24} />
              </div>
            </td>
          </tr>
        );
      });
    }

    return (
      <tr>
        <td colSpan={12}>brak produktów do wyświetlenia</td>
      </tr>
    );
  };

  return (
    <>
      <ProductFormComponent
        isCollapsing={isCollapsing}
        setIsCollapsing={setIsCollapsing}
        addProductInputRef={addProductInputRef}
        checkIfProductAlreadyExist={checkIfProductAlreadyExist}
      />
      <div className="row">
        <div className="col-6">
          <div className="form-group">
            <label htmlFor="search">Szukaj produktu</label>
            <input type="text" className="form-control" onChange={(e) => setQuery(e.target.value)} placeholder="Podaj frazę której szukasz" />
          </div>
        </div>
      </div>

      <table className="table">
        <thead className="thead-dark">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Zdjęcia</th>
            <th scope="col">Link</th>
            <th scope="col">Kod</th>
            <th scope="col">Kod klienta</th>
            <th scope="col">Nazwa</th>
            <th scope="col">Produkt</th>
            <th scope="col">Kategoria</th>
            <th scope="col">j.m.</th>
            <th scope="col">ilość w op. zb.</th>
            <th scope="col">Cena</th>
            <th scope="col">Akcja</th>
          </tr>
        </thead>
        <tbody>{renderProductList()}</tbody>
      </table>
    </>
  );
}

export default ProductsComponent;
