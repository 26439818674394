import * as React from 'react';

interface TooltipType {
  text: string;
  icon: React.ReactNode;
}

function IconTooltipComponent({ text, icon }: TooltipType) {
  const [showTooltip, setShowTooltip] = React.useState<boolean>(false);

  return (
    <>
      <div className="tooltip-icon" onMouseLeave={() => setShowTooltip(false)} onMouseEnter={() => setShowTooltip(true)}>
        {icon}
      </div>
      {showTooltip && <div className="tooltip-text">{text}</div>}
    </>
  );
}

export default IconTooltipComponent;
