import * as React from 'react';
import { useForm } from 'react-hook-form';
import { resetEditedProduct } from '../../features/productSlice';
import { showToast } from '../../features/appSlice';
import { yupResolver } from '@hookform/resolvers/yup';
import { productInitialState, productSchema } from './schemas/product.schema';
// import FilesInputComponent from '../application/filesInput.component';
import { ProductData } from '../../store/types';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useAddProductMutation, useEditProductMutation } from '../../services/product.service';
import CropperComponent from '../application/cropper.component';

interface ProductFormType {
  addProductInputRef: any; //@TODO TYPE FOR REF
  isCollapsing: boolean;
  setIsCollapsing: Function;
  checkIfProductAlreadyExist: (productCode: string) => boolean;
}

function ProductFormComponent({ addProductInputRef, isCollapsing, setIsCollapsing, checkIfProductAlreadyExist }: ProductFormType) {
  const editedProductState = useAppSelector((state) => state.products.editedProduct);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues,
    watch
  } = useForm({
    resolver: yupResolver(productSchema)
  });
  const dispatch = useAppDispatch();
  const [addProduct] = useAddProductMutation();
  const [editProduct] = useEditProductMutation();

  const watchPhoto1 = watch('photo1', false);
  const watchPhoto2 = watch('photo2', false);
  const watchPhoto3 = watch('photo3', false);
  const id = getValues('id');

  // UPDATE FORM IF EDITED PRODUCT EXIST
  React.useEffect(() => {
    if (editedProductState) {
      reset({ ...editedProductState });
    } else {
      reset({ ...productInitialState });
    }
  }, [editedProductState]);

  const onSubmit = handleSubmit(async (data: Partial<ProductData>) => {
    if (
      data &&
      data.code &&
      (!editedProductState || (editedProductState && editedProductState.code !== data.code)) &&
      checkIfProductAlreadyExist(data.code.trim())
    ) {
      dispatch(
        showToast({
          show: true,
          type: 'error',
          message: `Produkt o kodzie ${data.code} już istnieje na liście produktowej`
        })
      );
      return;
    }

    const response = await (id ? editProduct(data) : addProduct(data)).unwrap();
    window.scrollTo(0, 0);

    dispatch(
      showToast({
        show: true,
        type: response.success ? 'success' : 'error',
        message: response.success ? (id ? 'Produt został zaktualizowany' : 'Dodano produkt') : 'Wystąpił błąd'
      })
    );

    if (response.success) {
      // close add product form
      if (addProductInputRef.current) {
        addProductInputRef.current.click();
      }

      dispatch(resetEditedProduct());
      reset({ ...productInitialState });
    }
  });

  const handleCancelForm = () => {
    if (id) {
      dispatch(resetEditedProduct());
      reset({ ...productInitialState });
    }

    if (addProductInputRef.current) {
      addProductInputRef.current.click();
    }
  };

  return (
    <>
      <div className="buttons-wrapper">
        <div
          ref={addProductInputRef}
          onClick={() => setIsCollapsing(!isCollapsing)}
          data-toggle="collapse"
          data-target="#add-product-collapse"
          aria-expanded="false"
          aria-controls="add-product-collapse"
          className={`add-product-button ${isCollapsing ? 'isHide' : ''}`}>
          {editedProductState ? `Edytuj produkt ${editedProductState.productName}` : 'Dodaj produkt'}
        </div>
      </div>
      <div className="collapse wms-collapse" id="add-product-collapse">
        <div className="card card-body">
          <p>{editedProductState ? `Edytuj produkt ${editedProductState.productName}` : 'Dodaj produkt'}</p>
          <form id="addNewProduct" onSubmit={onSubmit}>
            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="kod">Kod</label>
                  <input {...register('code')} type="text" className="form-control" placeholder="podaj kod produktu" />
                  {errors.code && <span className="error-message">{errors.code.message}</span>}
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <label htmlFor="kod_klienta">Kod klienta</label>
                  <input {...register('clientCode')} type="text" className="form-control" placeholder="podaj kod klienta" />
                  {errors.clientCode && <span className="error-message">{errors.clientCode.message}</span>}
                </div>
              </div>
              <div className="col-3">
                <div className="form-group">
                  <label htmlFor="produkt">Produkt</label>
                  <input {...register('subProductName')} type="text" className="form-control" placeholder="np. pynoterapia" />
                  {errors.subProductName && <span className="error-message">{errors.subProductName.message}</span>}
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="nazwa_produktu">Nazwa produktu</label>
                  <input {...register('productName')} type="text" className="form-control" placeholder="podaj nazwa produktu" />
                  {errors.productName && <span className="error-message">{errors.productName.message}</span>}
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="kategoria_produktu">Kategoria produktu</label>
                  <input {...register('categoryName')} type="text" className="form-control" placeholder="podaj kategorie produktu" />
                  {errors.categoryName && <span className="error-message">{errors.categoryName.message}</span>}
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="link_produktu">Link (informacja o produkcie)</label>
                  <input {...register('link')} type="text" className="form-control" placeholder="podaj link do produktu" />
                  {errors.link && <span className="error-message">{errors.link.message}</span>}
                </div>
              </div>
              <div className="col-2">
                <div className="form-group">
                  <label htmlFor="jm_produktu">jednostka miary</label>
                  <select {...register('unit')} className="form-control">
                    <option value="">Wybierz</option>
                    <option value="szt">szt</option>
                    <option value="opakowanie">opakowanie</option>
                    <option value="paleta">paleta</option>
                  </select>
                  {errors.unit && <span className="error-message">{errors.unit.message}</span>}
                </div>
              </div>
              <div className="col-2">
                <div className="form-group">
                  <label htmlFor="ilosc_w_op_zb">Ilość w op. zb.</label>
                  <input {...register('amount')} type="number" className="form-control" placeholder="np. 50 szt." />
                  {errors.amount && <span className="error-message">{errors.amount.message}</span>}
                </div>
              </div>
              <div className="col-2">
                <div className="form-group">
                  <label htmlFor="cena_produktu">Cena produktu</label>
                  <input {...register('price')} type="number" className="form-control" placeholder="np. 150zł" />
                  {errors.price && <span className="error-message">{errors.price.message}</span>}
                </div>
              </div>
              <div className="col-12">
                <div className="form-group form-check  d-flex flex-row align-items-center">
                  <input {...register('isInvisible')} type="checkbox" className="form-check-input" id="exampleCheck1" />
                  <label className="form-check-label mt-1" htmlFor="exampleCheck1">
                    Produkt widoczny tylko dla administratora
                  </label>
                </div>
              </div>
              <div className="mr-3">
                <CropperComponent setValue={setValue} imagePath={watchPhoto1} fileName="photo1" photoNumber={1} />
              </div>
              <div className="mr-3">
                <CropperComponent setValue={setValue} imagePath={watchPhoto2} fileName="photo2" photoNumber={2} />
              </div>
              <div className="mr-3">
                <CropperComponent setValue={setValue} imagePath={watchPhoto3} fileName="photo3" photoNumber={3} />
              </div>
              {/* <div className="col-6">
                <div className="form-group">
                  <FilesInputComponent
                    setValue={setValue}
                    values={watchPhoto}
                    fieldName="photo"
                    accept="image/*"
                    isMultiple={false}
                    labelName="Zdjecie"
                  />
                </div>
              </div> */}
              {/* <div className="col-6">
                <FilesInputComponent
                  setValue={setValue}
                  values={watchFiles}
                  fieldName="files"
                  accept="application/pdf"
                  isMultiple={true}
                  labelName="Zdjęcie"
                />
              </div> */}
              <div className="col-12 submit-wrapper">
                <button onClick={handleCancelForm} type="button" className="cancel-button btn btn-secondary">
                  Anuluj
                </button>
                <button type="submit" className="submit-button btn btn-primary">
                  {id ? 'Edytuj produkt' : 'Dodaj produkt'}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default ProductFormComponent;
