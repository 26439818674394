import * as React from 'react';

import OrdersComponent from './orders.component';
import WarehousesComponent from './warehouses.component';
import ProductsComponent from './products.component';
import HistoryComponent from './history.component';
import ToastComponent from '../application/toast.component';

import { useGetOrdersToAcceptQuery } from '../../services/order.service';
import { useGetWarehouseHistoryQuery, useGetWarehouseProductsQuery } from '../../services/warehouse.service';
import { useGetProductsQuery } from '../../services/product.service';
import GroupHomeComponent from './groupHome.component';

function AdministrationComponent() {
  const { refetch: refetchOrderToAcceptance } = useGetOrdersToAcceptQuery();
  const { refetch: refetchWarehouse } = useGetWarehouseProductsQuery();
  const { refetch: refetchWarehouseProducts } = useGetProductsQuery();
  const { refetch: refetchWarehouseHistory } = useGetWarehouseHistoryQuery();

  const handleRefetchData = (type: string) => {
    switch (type) {
      case 'orderToAcceptance':
        refetchOrderToAcceptance();
        break;
      case 'warehouse':
        refetchWarehouse();
        break;
      case 'products':
        refetchWarehouseProducts();
        break;
      case 'history':
        refetchWarehouseHistory();
        break;
    }
  };

  return (
    <>
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        <li className="nav-item" onClick={() => handleRefetchData('orderToAcceptance')} role="presentation">
          <a className="nav-link active" id="orders-tab" data-toggle="tab" href="#orders" role="tab" aria-controls="orders" aria-selected="true">
            Zamówienia do akceptacji
          </a>
        </li>
        <li className="nav-item" onClick={() => handleRefetchData('warehouse')} role="presentation">
          <a className="nav-link" id="warehouse-tab" data-toggle="tab" href="#warehouse" role="tab" aria-controls="warehouse" aria-selected="false">
            Magazyn
          </a>
        </li>
        <li className="nav-item" onClick={() => handleRefetchData('products')} role="presentation">
          <a className="nav-link" id="products-tab" data-toggle="tab" href="#products" role="tab" aria-controls="products" aria-selected="false">
            Produkty
          </a>
        </li>
        <li className="nav-item" onClick={() => handleRefetchData('history')} role="presentation">
          <a className="nav-link" id="history-tab" data-toggle="tab" href="#history" role="tab" aria-controls="history" aria-selected="false">
            Historia magazynowa
          </a>
        </li>
        <li className="nav-item " onClick={() => handleRefetchData('group')} role="presentation">
          <a className="nav-link" id="group-tab" data-toggle="tab" href="#group" role="tab" aria-controls="group" aria-selected="false">
            Wysyłka grupowa
          </a>
        </li>
      </ul>
      <div className="tab-content" id="myTabContent">
        <div className="tab-pane fade show active" id="orders" role="tabpanel" aria-labelledby="orders-tab">
          <OrdersComponent />
        </div>
        <div className="tab-pane fade" id="warehouse" role="tabpanel" aria-labelledby="warehouse-tab">
          <WarehousesComponent />
        </div>
        <div className="tab-pane fade" id="products" role="tabpanel" aria-labelledby="products-tab">
          <ProductsComponent />
        </div>
        <div className="tab-pane fade" id="history" role="tabpanel" aria-labelledby="history-tab">
          <HistoryComponent />
        </div>
        <div className="tab-pane fade" id="group" role="tabpanel" aria-labelledby="group-tab">
          <GroupHomeComponent />
        </div>
      </div>
      <ToastComponent />
    </>
  );
}

export default AdministrationComponent;
