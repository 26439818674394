import React, { useEffect, useState } from 'react';
import { useFieldArray, UseFormRegister, Control, FieldErrors, FieldValues, UseFormWatch } from 'react-hook-form';
import { useAppSelector } from '../../store/hooks';
import { get as _get, findIndex as _findIndex } from 'lodash';
import { GroupOrderData, GroupOrderProduct, WarehouseProductData } from '../../store/types';

import { ReactComponent as IconReject } from '../../assets/reject-icon.svg';
import { ReactComponent as IconAdd } from '../../assets/add-icon.svg';

interface NestedPorductsArrayType {
  nestIndex: number;
  control: Control<GroupOrderData>;
  register: UseFormRegister<GroupOrderData>;
  errors: FieldErrors<FieldValues>;
  watch: UseFormWatch<GroupOrderData>;
  employeeName: string;
}

export default ({ nestIndex, control, register, errors, watch }: NestedPorductsArrayType) => {
  //@TODO CREATE NEW COMPONENT
  const [productsForSelect, setProductsForSelect] = useState<WarehouseProductData[]>([]);
  const allProducts = useAppSelector((state) => state.groups.productsWithStock);
  const productsStockSum = useAppSelector((state) => state.groups.productsStockSum);

  const { fields, remove, append, update } = useFieldArray({
    control,
    name: `orders.${nestIndex}.products`
  });

  useEffect(() => {
    if (allProducts && allProducts.length) {
      setProductsForSelect(allProducts);
    }
  }, [allProducts]);

  useEffect(() => {
    if (fields && fields.length) {
      const selectedProductIds: number[] = [];

      fields.map((field: GroupOrderProduct) => {
        if (field.productId) {
          selectedProductIds.push(field.productId);
        }
      });

      const selectProductsWithoutSelected = productsForSelect.filter(
        (product: WarehouseProductData) => !selectedProductIds.includes(product.productId)
      );
      setProductsForSelect(selectProductsWithoutSelected);
    }
  }, [fields]);

  const updateNewProductData = (productIndex: number, productId: number): void => {
    fields[productIndex].productId = productId;
    update(productIndex, fields[productIndex]);
  };

  const renderProductsSelector = (productIndex: number): React.ReactElement<HTMLSelectElement> => {
    return (
      <select className="form-control order-new-product-list" onChange={(e) => updateNewProductData(productIndex, parseInt(e.target.value))}>
        <option value="">Wybierz produkt</option>
        {renderProductOptions()}
      </select>
    );
  };

  const renderProductOptions = (): React.ReactElement<HTMLOptionElement[]> | null => {
    if (!productsForSelect) {
      return null;
    }

    return (
      <>
        {productsForSelect.map((product: WarehouseProductData, index: number) => {
          return (
            <option value={product.productId} key={`single-option-${index}`}>
              {`${product.code} - ${product.productName} (${product.amount} szt.)`}
            </option>
          );
        })}
      </>
    );
  };

  const handleAddNewEmptyProduct = (): void => {
    append({
      productId: null,
      amount: 1
    });
  };

  const renderProducts = (): React.ReactElement[] => {
    let productData: React.ReactElement[] = [];

    productData = fields.map((field: GroupOrderProduct, productIndex: number) => {
      const productData = allProducts.find((product: WarehouseProductData) => product.productId === field.productId);
      let productCurrentAmount = 0;

      if (productData && productData.amount && field.productId) {
        const currentTotalReservedAmount = productsStockSum.find((product: GroupOrderProduct) => product.productId === field.productId);

        if (currentTotalReservedAmount) {
          productCurrentAmount = productData.amountWithReservation - currentTotalReservedAmount.amount;
        }
      }

      return (
        <tr className="white-row" key={`group-single-order-single-product-${productIndex}`}>
          <td>{productIndex + 1}</td>
          <td>{productData ? productData.code : '-'}</td>
          <td>
            <div className="d-flex flex-column justify-content-center align-items-center">
              {field.productId ? (productData && productData.productName ? productData.productName : '-') : renderProductsSelector(productIndex)}
              {_get(errors, `orders.[${nestIndex}].products[${productIndex}].productId`) && (
                <div className="error-message">{_get(errors, `orders.[${nestIndex}].products[${productIndex}].productId.message`)}</div>
              )}
            </div>
          </td>
          <td className="d-flex flex-column justify-content-center align-items-center">
            {field.productId ? (
              <input
                {...register(`orders.${nestIndex}.products.${productIndex}.amount`, { setValueAs: (value) => parseInt(value, 10) })}
                type="number"
              />
            ) : (
              '-'
            )}
            {_get(errors, `orders.[${nestIndex}].products[${productIndex}].amount`) && (
              <span className="error-message">{_get(errors, `orders.[${nestIndex}].products[${productIndex}].amount.message`)}</span>
            )}
          </td>
          <td>
            <span className="warehouse-count">{productCurrentAmount}</span>
          </td>
          <td>
            <div onClick={() => remove(productIndex)}>
              <IconReject className="action-icon reject-order" width={24} />
            </div>
          </td>
        </tr>
      );
    });

    if (!productData.length) {
      productData.push(
        <tr key={`group-single-order-${nestIndex}-no-elements`}>
          <td colSpan={8}>brak produktów do wyświetlenia</td>
        </tr>
      );
    }

    productData.push(
      <tr key={`group-single=order-${nestIndex}-add-product-button`}>
        <td colSpan={8} className="p-0">
          <div className="add-product-button d-flex align-items-center justify-content-center p-2" onClick={() => handleAddNewEmptyProduct()}>
            <span className="mr-3">Dodaj produkt</span>
            <IconAdd width={24} />
          </div>
        </td>
      </tr>
    );

    return productData;
  };

  return <>{renderProducts()}</>;
};
