import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { showToast } from '../../features/appSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useForm } from 'react-hook-form';
import { useAddEmployeeMutation, useEditEmployeeMutation } from '../../services/group.service';
import { employeeInitialState, employeeSchema } from './schemas/employee.schema';
import { EmployeeData, GroupData } from '../../store/types';
import { resetEditedEmployee } from '../../features/groupSlice';
import SelectComponent, { SelectOption } from './select.component';

interface EmployeeFormType {
  addEmployeeInputRef: any; //@TODO TYPE FOR REF
  isCollapsing: boolean;
  setIsCollapsing: Function;
  checkIfEmployeeAlreadyExist: (employeeName: string) => boolean;
}

function GroupEmployeeFormComponent({ addEmployeeInputRef, isCollapsing, setIsCollapsing, checkIfEmployeeAlreadyExist }: EmployeeFormType) {
  const [groupOptions, setGroupOptions] = React.useState<SelectOption[]>([]);
  const allGroups = useAppSelector((state) => state.groups.groups);
  const editedEmployeeState = useAppSelector((state) => state.groups.editedEmployee);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues
  } = useForm({
    resolver: yupResolver(employeeSchema)
  });
  const dispatch = useAppDispatch();
  const [addEmployee] = useAddEmployeeMutation();
  const [editEmployee] = useEditEmployeeMutation();

  const id = getValues('id');

  React.useEffect(() => {
    if (editedEmployeeState) {
      reset({ ...editedEmployeeState });
    } else {
      reset({ ...employeeInitialState });
    }
  }, [editedEmployeeState]);

  React.useEffect(() => {
    if (allGroups && allGroups.length) {
      const optionsTmp: SelectOption[] = [];

      allGroups.map((group: GroupData) => {
        if (group.id) {
          optionsTmp.push({ id: group.id, value: group.name });
        }
      });

      setGroupOptions(optionsTmp);
    }
  }, [allGroups]);

  const onSubmit = handleSubmit(async (data: Partial<EmployeeData>) => {
    if (
      data &&
      data.firstName &&
      data.lastName &&
      (!employeeInitialState || (employeeInitialState && employeeInitialState.id && employeeInitialState.id !== data.id)) &&
      checkIfEmployeeAlreadyExist(`${data.firstName}  ${data.lastName}}`.trim())
    ) {
      dispatch(
        showToast({
          show: true,
          type: 'error',
          message: `Pracownik ${data.firstName}  ${data.lastName} już istnieje na liście pracowników`
        })
      );
      return;
    }

    const response = await (id ? editEmployee(data) : addEmployee(data)).unwrap();
    window.scrollTo(0, 0);

    dispatch(
      showToast({
        show: true,
        type: response.success ? 'success' : 'error',
        message: response.success ? (id ? 'Pracownik został zaktualizowany' : 'Dodano pracownika') : 'Wystąpił błąd'
      })
    );

    if (response.success) {
      // close add region form
      if (addEmployeeInputRef.current) {
        addEmployeeInputRef.current.click();
      }

      dispatch(resetEditedEmployee());
      reset({ ...employeeInitialState });
    }
  });

  const handleCancelForm = () => {
    if (id) {
      dispatch(resetEditedEmployee());
      reset({ ...employeeInitialState });
    }

    if (addEmployeeInputRef.current) {
      addEmployeeInputRef.current.click();
    }
  };

  return (
    <>
      <div className="buttons-wrapper">
        <div
          ref={addEmployeeInputRef}
          onClick={() => setIsCollapsing(!isCollapsing)}
          data-toggle="collapse"
          data-target="#add-employee-collapse"
          aria-expanded="false"
          aria-controls="add-employee-collapse"
          className={`add-employee-button ${isCollapsing ? 'isHide' : ''}`}>
          {editedEmployeeState ? `Edytuj pracownika ${editedEmployeeState.firstName} ${editedEmployeeState.lastName}` : 'Dodaj pracownika'}
        </div>
      </div>
      <div className="collapse employee-collapse" id="add-employee-collapse">
        <div className="card card-body">
          <p>{editedEmployeeState ? `Edytuj pracownika ${editedEmployeeState.firstName} ${editedEmployeeState.lastName}` : 'Dodaj pracownika'}</p>
          <form id="addNewEmployee" onSubmit={onSubmit}>
            <div className="row">
              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="firstName">Imię</label>
                  <input {...register('firstName')} type="text" className="form-control" placeholder="podaj imię pracownika" />
                  {errors.firstName && <span className="error-message">{errors.firstName.message}</span>}
                </div>
              </div>

              <div className="col-5">
                <div className="form-group">
                  <label htmlFor="lastName">Nazwisko</label>
                  <input {...register('lastName')} type="text" className="form-control" placeholder="podaj nazwisko pracownika" />
                  {errors.lastName && <span className="error-message">{errors.lastName.message}</span>}
                </div>
              </div>

              <div className="col-3">
                <div className="form-group">
                  <label htmlFor="phone">Nr. telefonu</label>
                  <input {...register('phone')} type="number" className="form-control" placeholder="podaj telefon pracownika" />
                  {errors.phone && <span className="error-message">{errors.phone.message}</span>}
                </div>
              </div>

              <div className="col-3">
                <div className="form-group">
                  <label htmlFor="email">E-mail</label>
                  <input {...register('email')} type="text" className="form-control" placeholder="podaj e-mail pracownika" />
                  {errors.email && <span className="error-message">{errors.email.message}</span>}
                </div>
              </div>

              <div className="col-3">
                <div className="form-group">
                  <label htmlFor="street">Ulica</label>
                  <input {...register('street')} type="text" className="form-control" placeholder="podaj ulicę" />
                  {errors.street && <span className="error-message">{errors.street.message}</span>}
                </div>
              </div>

              <div className="col-3">
                <div className="form-group">
                  <label htmlFor="streetNumber">Numer ulicy</label>
                  <input {...register('streetNumber')} type="text" className="form-control" placeholder="podaj numer ulicy" />
                  {errors.streetNumber && <span className="error-message">{errors.streetNumber.message}</span>}
                </div>
              </div>

              <div className="col-3">
                <div className="form-group">
                  <label htmlFor="postalCode">Kod pocztowy</label>
                  <input {...register('postalCode')} type="text" className="form-control" placeholder="podaj kod pocztowy" />
                  {errors.postalCode && <span className="error-message">{errors.postalCode.message}</span>}
                </div>
              </div>

              <div className="col-3">
                <div className="form-group">
                  <label htmlFor="city">Miasto</label>
                  <input {...register('city')} type="text" className="form-control" placeholder="podaj miasto" />
                  {errors.city && <span className="error-message">{errors.city.message}</span>}
                </div>
              </div>

              <div className="col-3">
                <SelectComponent register={register} fieldName="groupId" label="Grupa" errors={errors} optionData={groupOptions} />
              </div>

              <div className="col-12 submit-wrapper">
                <button onClick={handleCancelForm} type="button" className="cancel-button btn btn-secondary">
                  Anuluj
                </button>
                <button type="submit" className="submit-button btn btn-primary">
                  {id ? 'Edytuj pracownika' : 'Dodaj pracownika'}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default GroupEmployeeFormComponent;
