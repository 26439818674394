import * as React from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { showToast } from '../../features/appSlice';

function ToastComponent() {
  const dispatch = useAppDispatch();
  const toastState = useAppSelector((state) => state.app.toast);

  React.useEffect(() => {
    if (toastState && toastState.show) {
      setTimeout(() => {
        dispatch(
          showToast({
            show: false,
            type: '',
            message: ''
          })
        );
      }, 8000);
    }
  }, [toastState]);

  return (
    <>
      {toastState.show && (
        <div>
          {toastState.type === 'error' && (
            <div className="toast toast-error">
              <span>{toastState.message}</span>
            </div>
          )}
          {toastState.type === 'success' && (
            <div className="toast toast-success">
              <span>{toastState.message}</span>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default ToastComponent;
