import * as React from 'react';
import { useGetUedEmailsQuery } from '../../services/app.service';
import _ from 'lodash';
import { ReactComponent as IconReject } from '../../assets/reject-icon.svg';

interface AcceptanceModalType {
  handleSendOrderlToUed: Function;
  modalHref: string;
}

function SendOrderToUedModalComponent({ handleSendOrderlToUed, modalHref }: AcceptanceModalType) {
  const { data: uedMailsData } = useGetUedEmailsQuery();
  const [mailsToSend, setMailsToSend] = React.useState<string[]>([]);
  const [mailIndexesWithError, setMailIndexesWithError] = React.useState<number[]>([]);
  const closeUedModalRef = React.useRef<any>();

  React.useEffect(() => {
    if (uedMailsData && uedMailsData.documents.length) {
      const uedEmails: string[] = uedMailsData.documents;
      setMailsToSend(uedEmails);
    }
  }, [uedMailsData]);

  const addNewUedEmail = () => {
    const emails = [...mailsToSend];
    emails.push('');
    setMailsToSend(emails);
  };

  const inputOnChange = (e: React.FormEvent<HTMLInputElement>, index: number) => {
    const emails = [...mailsToSend];
    emails[index] = e.currentTarget.value;

    if (mailIndexesWithError.includes(index) && e.currentTarget.value !== '') {
      let mailIndexes = [...mailIndexesWithError];
      mailIndexes = mailIndexes.filter((emailIndex: number) => emailIndex !== index);
      setMailIndexesWithError(mailIndexes);
    }

    setMailsToSend(emails);
  };

  const removeEmail = (emailIndex: number) => {
    let emails = [...mailsToSend];
    emails = emails.filter((email: string, index: number) => index !== emailIndex);
    setMailsToSend(emails);
    validateEmails(emails);
  };

  const validateEmails = (mails: string[]): boolean => {
    const regexEmailExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const someEmptyEmails = mails.filter((email: string) => email === '' || !regexEmailExp.test(email));
    const emptyEmailIndexes: number[] = [];

    if (someEmptyEmails.length) {
      mails.map((email: string, index: number) => {
        if (email === '' || !regexEmailExp.test(email)) {
          emptyEmailIndexes.push(index);
        }
      });
    }

    setMailIndexesWithError(emptyEmailIndexes);
    return !!someEmptyEmails.length;
  };

  const handleSendMails = (mails: string[]) => {
    setMailIndexesWithError([]);
    const isInvalid = validateEmails(mails);

    if (!isInvalid) {
      closeUedModalRef.current.click();
      handleSendOrderlToUed(mailsToSend);
    }
  };

  const renderEmailInputs = () => {
    if (!mailsToSend.length) {
      setMailsToSend(['']);
      return;
    }

    return mailsToSend.map((email: string, index: number) => {
      const isEmpty: boolean = mailIndexesWithError.includes(index);

      return (
        <div key={`single-ued-email-key-${index}`} className="single-ued-email">
          <input className={isEmpty ? 'has-error' : ''} onChange={(e) => inputOnChange(e, index)} value={email} type="email" />
          {mailsToSend.length > 1 && (
            <div className="remove-ued-email-button">
              <IconReject width={24} onClick={() => removeEmail(index)} />
            </div>
          )}
          {index === mailsToSend.length - 1 && (
            <button onClick={addNewUedEmail} className="add-ued-email btn btn-primary">
              dodaj kolejny adres
            </button>
          )}
        </div>
      );
    });
  };

  return (
    <>
      <div className="ued-modal modal fade" id={modalHref} tabIndex={-1} role="dialog" aria-labelledby={modalHref} aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="sendOrderToUedModalLabel">
                Wyślij zamówienie do UED
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div>
                <strong>Czy na pewno chcesz wysłać to zamówienie do realizacji do UED?</strong>
              </div>
              <br></br>
              <div>Poniżej adresy mailowe, na które wyślę się zamówienie:</div>
              <div className="ued-emails-wrapper">{renderEmailInputs()}</div>
              {!!mailIndexesWithError.length && <div className="ued-emails-error-message">Uzupełnij wszystkie pola o poparwny adres mailowy</div>}
            </div>
            <div className="modal-footer">
              <button type="button" ref={closeUedModalRef} data-dismiss="modal" className="submit-button hidden-button btn btn-primary"></button>
              <button type="button" className="cancel-button btn btn-secondary" data-dismiss="modal">
                NIE
              </button>
              <button type="button" onClick={() => handleSendMails(mailsToSend)} className="submit-button btn btn-primary">
                TAK, WYŚLIJ
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SendOrderToUedModalComponent;
