import React from 'react';
import { EmployeeData, GroupData } from '../../store/types';
import { useGetEmployeesQuery } from '../../services/group.service';
import GroupEmployeeFormComponent from './groupEmployeeForm.component';
import { ReactComponent as IconEdit } from '../../assets/edit-icon.svg';
import { setEditedEmployee } from '../../features/groupSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import _ from 'lodash';

function GroupEmployeesComponent() {
  const dispatch = useAppDispatch();
  const [query, setQuery] = React.useState<string>('');
  const [isCollapsing, setIsCollapsing] = React.useState<boolean>(false);
  const addEmployeeInputRef = React.useRef<any>(null);
  const allGroups = useAppSelector((state) => state.groups.groups);
  const { data: employeeData } = useGetEmployeesQuery();

  const checkIfEmployeeAlreadyExist = (employeeName: string) => {
    if (employeeData && !_.isEmpty(employeeData.documents)) {
      let allEmployees: EmployeeData[] = employeeData.documents;
      return !!allEmployees.find((employee: EmployeeData) => `${employee.firstName} ${employee.lastName}` === employeeName);
    }
    return false;
  };

  const handleEditEmployee = (employeeId: number) => {
    if (employeeData && _.get(employeeData, 'documents', []).length) {
      const editedEmployee: EmployeeData | undefined = employeeData.documents.find((employee: EmployeeData) => employee.id === employeeId);

      if (editedEmployee) {
        dispatch(setEditedEmployee(editedEmployee));

        if (addEmployeeInputRef.current && !isCollapsing) {
          addEmployeeInputRef.current.click();
        }
        window.scrollTo(0, 0);
      }
    }
  };

  const renderUserList = () => {
    if (employeeData && !_.isEmpty(employeeData.documents)) {
      let employees: EmployeeData[] = employeeData.documents;

      if (!employees.length) {
        return (
          <tr>
            <td align="center" colSpan={11}>
              brak pracowników do wyświetlenia
            </td>
          </tr>
        );
      }

      if (query) {
        const filteredGroupIds: number[] = [];
        const filteredGroupsByName: GroupData[] = allGroups.filter((group: GroupData) => group.name.match(new RegExp(query, 'gi')));

        filteredGroupsByName.map((group: GroupData) => {
          if (group.id) {
            filteredGroupIds.push(group.id);
          }
        });

        employees = employees.filter((employee: EmployeeData) => {
          let finded = false;

          if (employee.firstName.match(new RegExp(query, 'gi'))) {
            finded = true;
          }

          if (employee.lastName.match(new RegExp(query, 'gi'))) {
            finded = true;
          }

          if (filteredGroupIds.includes(employee.groupId)) {
            finded = true;
          }

          return finded;
        });
      }

      return employees.map((employee: EmployeeData, index: number) => {
        const rowClass = index % 2 === 0 ? 'white-row' : 'grey-row';

        let groupName = '-';

        if (employee.groupId && allGroups && allGroups.length) {
          const findedGroup: GroupData | undefined = allGroups.find((group: GroupData) => group.id === employee.groupId);
          if (findedGroup && findedGroup.name) {
            groupName = findedGroup.name;
          }
        }

        return (
          <tr className={`${rowClass}`} key={`single-employee-row-${index}`}>
            <td>{index + 1}</td>
            <td>{employee.firstName}</td>
            <td>{employee.lastName}</td>
            <td>{employee.phone}</td>
            <td>{employee.email}</td>
            <td>
              {employee.street} {employee.streetNumber}, {employee.postalCode} {employee.city}
            </td>
            <td>{groupName}</td>
            <td>
              <div onClick={() => employee.id && handleEditEmployee(employee.id)}>
                <IconEdit width={24} />
              </div>
            </td>
          </tr>
        );
      });
    }

    return (
      <tr>
        <td align="center" colSpan={11}>
          brak pracowników do wyświetlenia
        </td>
      </tr>
    );
  };

  return (
    <>
      <GroupEmployeeFormComponent
        isCollapsing={isCollapsing}
        setIsCollapsing={setIsCollapsing}
        addEmployeeInputRef={addEmployeeInputRef}
        checkIfEmployeeAlreadyExist={checkIfEmployeeAlreadyExist}
      />

      <div className="row">
        <div className="col-6">
          <div className="form-group">
            <label htmlFor="search">Szukaj użytkownika</label>
            <input
              type="text"
              className="form-control"
              onChange={(e) => setQuery(e.target.value)}
              placeholder="Szukaj użytkownika (imię, nazwisko, grupa)"
            />
          </div>
        </div>
      </div>

      <table className="table">
        <thead className="thead-dark">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Imię</th>
            <th scope="col">Nazwisko</th>
            <th scope="col">Telefon</th>
            <th scope="col">E-mail</th>
            <th scope="col">Adres</th>
            <th scope="col">Przypisana grupa</th>
            <th scope="col" style={{ width: '50px' }}>
              Akcja
            </th>
          </tr>
        </thead>
        <tbody>{renderUserList()}</tbody>
      </table>
    </>
  );
}

export default GroupEmployeesComponent;
