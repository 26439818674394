import * as yup from 'yup';
import { GroupAddProductToOrder } from '../../../store/types';

export const groupAddProductInitialState: GroupAddProductToOrder = {
  productId: '',
  amount: 1,
};

export const groupAddProductSchema = yup.object().shape({
  productId: yup.string().required('Produkt jest wymagany'),
  amount: yup.number().min(1, 'Minimalna ilość to 1').required('Ilość jest wymagana'),
});
