import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProductData } from '../store/types';

interface ProductState {
  editedProduct: ProductData | null;
  products: ProductData[];
}

const initialState: ProductState = {
  editedProduct: null,
  products: []
};

export const productSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setEditedProduct: (state, action: PayloadAction<ProductData | null>) => {
      state.editedProduct = action.payload;
    },
    resetEditedProduct: (state) => {
      state.editedProduct = null;
    },
    setProducts: (state, action: PayloadAction<ProductData[]>) => {
      state.products = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setEditedProduct, resetEditedProduct, setProducts } = productSlice.actions;

export default productSlice.reducer;
