import * as yup from 'yup';
import { GroupOrderData } from '../../../store/types';

export const groupOrderInitialState: GroupOrderData = {
  orders: []
};

export const groupOrderSchema = yup.object().shape({
  orders: yup.array(yup.object({
    products: yup.array(yup.object({
      productId: yup.number().typeError('Wybierz produkt z listy').required('Identyfikator produktu jest wymagany'),
      name: yup.string(),
      amount: yup.number().typeError('Wpisz poprawnie ilość').min(1, 'Minimalna ilość to 1')
    })).min(1, 'Dodaj przynajmniej jeden produkt lub usuń pracownika'),
    orderPerson: yup.string().required('Imie i nazwisko jest wymagane'),
    orderPersonEmail: yup.string().email('Wpisz poparwny adres e-mail').required('Osoba do kontaktu jest wymagana'),
    recipientName: yup.string().required('Nazwa odbiory jest wymagana'),
    recipientPerson: yup.string().required('Nazwa odbiory jest wymagana'),
    recipientPhone: yup.string().required('Numer kontaktowy jest wymagany'),
    recipientEmail: yup.string().email('Wpisz poparwny adres e-mail'),
    recipientAddress: yup.string().required('Adres jest wymagany'),
    recipientCity: yup.string().required('Miasto jest wymagane'),
    recipientPostalCode: yup.string().required('Kod pocztowy jest wymagany'),
    recipientId: yup.string(),
    isExpanded: yup.boolean(),
  })).min(1, 'Dodaj przynajmniej jednego pracownika')
});
