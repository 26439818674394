import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProductData, WarehouseProductData } from '../store/types';

interface WarehouseState {
  editedProduct: WarehouseProductData | null;
  products: ProductData[];
}

const initialState: WarehouseState = {
  editedProduct: null,
  products: []
};

export const warehouseSlice = createSlice({
  name: 'warehouses',
  initialState,
  reducers: {
    setEditedProduct: (state, action: PayloadAction<WarehouseProductData | null>) => {
      state.editedProduct = action.payload;
    },
    resetEditedProduct: (state) => {
      state.editedProduct = null;
    },
    setProducts: (state, action: PayloadAction<ProductData[]>) => {
      state.products = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setEditedProduct, resetEditedProduct, setProducts } = warehouseSlice.actions;

export default warehouseSlice.reducer;
