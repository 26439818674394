import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EmployeeData, GroupData, GroupOrderProduct, WarehouseProductData } from '../store/types';

interface EmployeeState {
  editedEmployee: EmployeeData | null;
  editedGroup: GroupData | null;
  groups: GroupData[];
  employees: EmployeeData[];
  productsWithStock: WarehouseProductData[];
  productsStockSum: GroupOrderProduct[];
}

const initialState: EmployeeState = {
  editedEmployee: null,
  editedGroup: null,
  groups: [],
  employees: [],
  productsWithStock: [],
  productsStockSum: []
};

export const groupSlice = createSlice({
  name: 'group',
  initialState,
  reducers: {
    setEditedEmployee: (state, action: PayloadAction<EmployeeData | null>) => {
      state.editedEmployee = action.payload;
    },
    resetEditedEmployee: (state) => {
      state.editedEmployee = null;
    },
    setEditedGroup: (state, action: PayloadAction<GroupData | null>) => {
      state.editedGroup = action.payload;
    },
    resetEditedGroup: (state) => {
      state.editedGroup = null;
    },
    setGroups: (state, action: PayloadAction<GroupData[]>) => {
      state.groups = action.payload;
    },
    setEmployees: (state, action: PayloadAction<EmployeeData[]>) => {
      state.employees = action.payload;
    },
    setProductWithStock: (state, action: PayloadAction<WarehouseProductData[]>) => {
      state.productsWithStock = action.payload;
    },
    setProductsStockSum: (state, action: PayloadAction<GroupOrderProduct[]>) => {
      state.productsStockSum = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setEditedEmployee, resetEditedEmployee, setEditedGroup, resetEditedGroup, setGroups, setEmployees, setProductWithStock, setProductsStockSum } = groupSlice.actions;

export default groupSlice.reducer;
