import * as yup from 'yup';
import { GroupAddGroupToOrder } from '../../../store/types';

export const groupAddGroupInitialState: GroupAddGroupToOrder = {
  groupId: '',
};

export const groupAddGroupSchema = yup.object().shape({
  groupId: yup.string().required('Grupa jest wymagana'),
});
