import { PixelCrop } from 'react-image-crop';

const setCanvasPreview = (image: HTMLImageElement, canvas: HTMLCanvasElement, crop: PixelCrop) => {
  const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d');
  if (!ctx) {
    throw new Error('No 2d context');
  }

  const pixelRatio: number = window.devicePixelRatio;
  const scaleX: number = image.naturalWidth / image.width;
  const scaleY: number = image.naturalHeight / image.height;

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = 'high';
  ctx.save();

  const cropX: number = crop.x * scaleX;
  const cropY: number = crop.y * scaleY;

  ctx.translate(-cropX, -cropY);
  ctx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight, 0, 0, image.naturalWidth, image.naturalHeight);
  ctx.restore();
};

export default setCanvasPreview;
