import * as React from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { showPopup } from '../../features/appSlice';

function PopupComponent() {
  const dispatch = useAppDispatch();
  const popupState = useAppSelector((state) => state.app.popup);

  React.useEffect(() => {
    if (popupState && popupState.show) {
      setTimeout(() => {
        dispatch(
          showPopup({
            show: false,
            message: ''
          })
        );
      }, 4000);
    }
  }, [popupState]);

  return (
    <>
      {popupState.show && (
        <div>
          <div className="app-popup">
            <div dangerouslySetInnerHTML={{ __html: popupState.message }}></div>
          </div>
        </div>
      )}
    </>
  );
}

export default PopupComponent;
