import * as yup from 'yup';
import { GroupData } from '../../../store/types';

export const groupInitialState: GroupData = {
  name: '',
};

export const groupSchema = yup.object().shape({
  id: yup.string(),
  name: yup.string().required('Nazwa jest wymagana'),
});
