import { ReactElement } from 'react';
import { FieldErrors, FieldValues, UseFormRegister } from 'react-hook-form';

interface SelectType {
  register: UseFormRegister<any>;
  label: string;
  fieldName: string;
  errors: FieldErrors<FieldValues>;
  optionData: SelectOption[];
}

export interface SelectOption {
  id: number;
  value: string;
}

function SelectComponent({ register, label, fieldName, errors, optionData }: SelectType) {
  const renderSelectOptions = (): ReactElement[] | null => {
    if (optionData && optionData.length) {
      return optionData.map((option: SelectOption, index: number) => {
        return (
          <option key={`select-option-${fieldName}-${index}`} value={option.id}>
            {option.value}
          </option>
        );
      });
    }

    return null;
  };

  return (
    <div className="form-group">
      <label htmlFor="jm_produktu">{label}</label>
      <select {...register(fieldName)} className="form-control">
        <option value="">Wybierz</option>
        {renderSelectOptions()}
      </select>
      {errors[fieldName] && <span className="error-message">{errors[fieldName].message}</span>}
    </div>
  );
}

export default SelectComponent;
