import { OrderSummaryData, OrderSummaryProduct } from '../../store/types';

interface OrderSummaryType {
  summaryData: OrderSummaryData;
  isLoading: boolean;
  onSubmit: () => void;
  onCancel: () => void;
}

function OrderSummaryComponent({ summaryData, isLoading, onSubmit, onCancel }: OrderSummaryType) {
  const getProductsView = () => {
    if (!summaryData.products.length) {
      return (
        <div>
          <strong>brak produktów</strong>
        </div>
      );
    }

    return summaryData.products.map((product: OrderSummaryProduct, index: number) => {
      const rowClass = index % 2 === 0 ? 'white-row' : 'grey-row';
      return (
        <div className={`${rowClass} d-flex summary-single-product`} key={`summary-prod-row-${index}`}>
          <div className="flex-fill w-75">
            <span className="summary-product-lp">{index + 1}.</span>
            {product.name}
          </div>
          <div className="flex-fill w-25 text-center">{product.amount}</div>
        </div>
      );
    });
  };
  return (
    <>
      <div className="order-summary-wrapper">
        <div className="summary-content">
          <h6 className="mb-4">Podsumowanie zamówienia</h6>
          <div className="summary-data">
            <div className="summary-label mb-2">Dane Zamawiającego</div>
            <div>
              <strong>Zamawiający: </strong> {summaryData.orderPerson}
            </div>
            <div>
              <strong>E-mail zamawiającego: </strong> {summaryData.orderPersonEmail}
            </div>
            <div className="summary-label mb-2 mt-3">Dane Odbiorcy</div>
            <div>
              <strong>Nazwa odbiorcy: </strong> {summaryData.recipientName}
            </div>
            <div>
              <strong>Imię i nazwisko: </strong> {summaryData.recipientPerson}
            </div>
            <div>
              <strong>E-mail: </strong> {summaryData.recipientEmail}
            </div>
            <div>
              <strong>Numer telefonu: </strong> {summaryData.recipientPhone}
            </div>
            <div>
              <strong>Adres dostawy: </strong> {summaryData.recipientAddress}, {summaryData.recipientPostalCode}, {summaryData.recipientCity}
            </div>
            <div className="summary-label mb-2 mt-3">Produkty w zamówieniu</div>
            <div className="summary-product-wrapper">
              <div className="d-flex product-content-label-wrapper">
                <div className="flex-fill mr-1 w-75">Produkt</div>
                <div className="flex-fill w-25">Ilość</div>
              </div>
              <div className="product-content">{getProductsView()}</div>
            </div>
          </div>
          <div className="mt-2">Jeżeli wszystkie informacje na zamówienie są poprawne, wyślij zamówienie do realizacji.</div>
          <div className="summary-button-section d-flex justify-content-end">
            <button type="button" onClick={onCancel} className="cancel-button btn btn-primary mr-3">
              Edytuj zamówienie
            </button>

            <button disabled={isLoading} type="button" onClick={onSubmit} className="submit-button btn btn-primary">
              Wyślij zamówienie do realizacji
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default OrderSummaryComponent;
