import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OrderData } from '../store/types';

interface OrderState {
  orderHistory: OrderData[] | null;
}

const initialState: OrderState = {
  orderHistory: []
};

export const orderSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setOrderHistory: (state, action: PayloadAction<OrderData[] | null>) => {
      state.orderHistory = action.payload;
    },
    resetOrderHistory: (state) => {
      state.orderHistory = [];
    }
  }
});

// Action creators are generated for each case reducer function
export const { setOrderHistory, resetOrderHistory } = orderSlice.actions;

export default orderSlice.reducer;
