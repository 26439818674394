import * as React from 'react';
import { API_HOST } from '../../store/types';

interface ImageType {
  photo: string | null;
}

function ImageComponent({ photo }: ImageType) {
  return (
    <>
      {photo && <img alt="" className="product-img" width="50px" height="50px" src={`${API_HOST}${photo}`} />}
      {photo && <img alt="" className="product-img-hidden" width="50px" height="50px" src={`${API_HOST}${photo}`} />}
      {!photo && <span>brak</span>}
    </>
  );
}

export default ImageComponent;
