import * as yup from 'yup';
import { GroupAddUserToOrder } from '../../../store/types';

export const groupAddUserInitialState: GroupAddUserToOrder = {
  userId: '',
};

export const groupAddUserSchema = yup.object().shape({
  userId: yup.string().required('Użytkownik jest wymagany'),
});
