import * as React from 'react';
import { useForm } from 'react-hook-form';
import { resetEditedProduct } from '../../features/warehouseSlice';
import { showToast } from '../../features/appSlice';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';
import { warehouseProductInitialState, warehouseProductSchema } from './schemas/warehouseProduct.schema';
import { ProductData, WarehouseProductData } from '../../store/types';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useAddProductToWarehouseMutation, useEditProductInWarehouseMutation } from '../../services/warehouse.service';

interface WarehouseFormType {
  addProductInputRef: any; //@TODO TYPE FOR REF
  isCollapsing: boolean;
  setIsCollapsing: Function;
  warehouseProductIds: number[];
}

function WarehouseFormComponent({ addProductInputRef, isCollapsing, setIsCollapsing, warehouseProductIds }: WarehouseFormType) {
  const editedProductState = useAppSelector((state) => state.warehouses.editedProduct);
  const productList = useAppSelector((state) => state.warehouses.products);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues
  } = useForm({
    resolver: yupResolver(warehouseProductSchema)
  });
  const dispatch = useAppDispatch();
  const [addProductToWarehouse] = useAddProductToWarehouseMutation();
  const [editProductInWarehouse] = useEditProductInWarehouseMutation();
  const id = getValues('id');
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  // UPDATE FORM IF EDITED PRODUCT EXIST
  React.useEffect(() => {
    if (editedProductState) {
      reset({ ...editedProductState });
    }
  }, [editedProductState]);

  const onSubmit = handleSubmit(async (data: Partial<WarehouseProductData>) => {
    setIsLoading(true);

    const response = await (id ? editProductInWarehouse(data) : addProductToWarehouse(data)).unwrap();

    if (response.success) {
      dispatch(
        showToast({
          show: true,
          type: response.success ? 'success' : 'error',
          message: response.success ? (id ? 'Produt został zaktualizowany w magazynie' : 'Dodano produkt do magazynu') : 'Wystąpił błąd'
        })
      );

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);

      // close add product form
      if (addProductInputRef.current) {
        addProductInputRef.current.click();
      }

      dispatch(resetEditedProduct());
      reset({ ...warehouseProductInitialState });
    }
  });

  const handleCancelForm = () => {
    if (id) {
      dispatch(resetEditedProduct());
      reset({ ...warehouseProductInitialState });
    }

    if (addProductInputRef.current) {
      addProductInputRef.current.click();
    }
  };

  const renderProductSelector = () => {
    if (!productList) {
      return;
    }

    let currentProductList = productList;

    if (!id && warehouseProductIds.length) {
      currentProductList = productList.filter((product: ProductData) => {
        return !_.includes(warehouseProductIds, product.id);
      });
    }

    return (
      <>
        {currentProductList.map((product: ProductData, index: number) => {
          return (
            <option value={product.id} key={`single-option-${index}`}>
              {`${product.code} - ${product.productName}`}
            </option>
          );
        })}
      </>
    );
  };

  return (
    <>
      <div className="buttons-wrapper">
        <div
          ref={addProductInputRef}
          onClick={() => setIsCollapsing(!isCollapsing)}
          data-toggle="collapse"
          data-target="#add-product-to-warehouse-collapse"
          aria-expanded="false"
          aria-controls="add-product-to-warehouse-collapse"
          className={`add-to-warehouse-button ${isCollapsing ? 'isHide' : ''}`}>
          Dodaj produkt do magazynu
        </div>
      </div>
      <div className="collapse wms-collapse" id="add-product-to-warehouse-collapse">
        <div className="card card-body">
          <p>{editedProductState ? `Edytuj produkt na magazynie: ${editedProductState.productName}` : 'Dodaj produkt na magazyn'}</p>
          <form id="addProductToWarehouse" onSubmit={onSubmit}>
            <div className="row">
              <div className="col-8">
                <div className="form-group">
                  <label htmlFor="lista_produktowa">Wybierz produkt</label>
                  <select disabled={id ? true : false} {...register('productId')} className="form-control" id="lista_produktowa">
                    <option value="">Wybierz produkt</option>
                    {renderProductSelector()}
                  </select>
                  {errors.productId && <span className="error-message">{errors.productId.message}</span>}
                </div>
              </div>
              <div className="col-4">
                <div className="form-group">
                  <label htmlFor="ilosc_na_magazynie">Ilość magazynowa</label>
                  <input {...register('amount')} type="number" className="form-control" id="ilosc_na_magazynie" placeholder="np. 50 szt." />
                  {errors.amount && <span className="error-message">{errors.amount.message}</span>}
                </div>
              </div>
              <div className="col-12 submit-wrapper">
                <button onClick={handleCancelForm} type="button" className="cancel-button btn btn-secondary">
                  Anuluj
                </button>
                <button type="submit" disabled={isLoading} className="submit-button btn btn-primary">
                  {id ? 'Edytuj produkt' : 'Dodaj produkt'}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default WarehouseFormComponent;
