import { configureStore } from '@reduxjs/toolkit';
// reducers
import appReducer from '../features/appSlice';
import productReducer from '../features/productSlice';
import warehouseReducer from '../features/warehouseSlice';
import orderReducer from '../features/orderSlice';
import groupReducer from '../features/groupSlice';

// services
import { appApi } from '../services/app.service';
import { productApi } from '../services/product.service';
import { warehouseApi } from '../services/warehouse.service';
import { orderApi } from '../services/order.service';
import { fileApi } from '../services/file.service';
import { groupApi } from '../services/group.service';

export const store = configureStore({
  reducer: {
    app: appReducer,
    products: productReducer,
    warehouses: warehouseReducer,
    orders: orderReducer,
    groups: groupReducer,
    // Add the generated reducer as a specific top-level slice
    [appApi.reducerPath]: appApi.reducer,
    [productApi.reducerPath]: productApi.reducer,
    [warehouseApi.reducerPath]: warehouseApi.reducer,
    [orderApi.reducerPath]: orderApi.reducer,
    [fileApi.reducerPath]: fileApi.reducer,
    [groupApi.reducerPath]: groupApi.reducer,
  },

  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(appApi.middleware)
      .concat(productApi.middleware)
      .concat(warehouseApi.middleware)
      .concat(orderApi.middleware)
      .concat(fileApi.middleware)
      .concat(groupApi.middleware)
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
