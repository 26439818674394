export const API_HOST = 'https://api-wms.kopettimakarone.usermd.net/';
// export const API_HOST = 'http://localhost:3001/';
export const AUTH_TOKEN = 'dsdseyJhbGciOiJFUzUxM';

export interface ProductData {
  id?: number;
  code: string;
  clientCode: string;
  productName: string;
  subProductName: string;
  categoryName: string;
  unit: string;
  amount: string;
  link: string;
  price: string;
  photo1: string;
  photo2: string;
  photo3: string;
  files: string[];
  isHidden?: boolean;
  isInvisible?: boolean;
}

export interface ProductResponse extends Response {
  id: string;
}

export interface ProductsResponse extends Response {
  documents: [ProductData];
}

// WAREHOUSES
export interface WarehouseProductData {
  id?: number;
  productId: number;
  code: string;
  productName: string;
  subProductName: string;
  photo: string[];
  categoryName: string;
  amount: string;
  amountWithReservation: number;
  reservationAmount?: number;
}

export interface WarehouseProductResponse extends Response {
  id: string;
}

export interface WarehouseProductsResponse extends Response {
  documents: [WarehouseProductData];
}

export enum HistoryType {
  ORDER = 'ORDER',
  MANUAL = 'MANUAL'
}

export interface WarehouseHistoryProduct {
  id: number;
  historyId: number;
  code: string;
  productId: number;
  productName: string;
  valueBefore: number;
  valueAfter: number;
}

export interface WarehouseHistoryData {
  id: string;
  date: string;
  type: HistoryType;
  userName: string;
  userFromOrder: string;
  products: WarehouseHistoryProduct[];
  uedSend: boolean;
  orderId: number;
  uedMails: string;
  groupOrderId: number;
  groupOrderPerson: string;
  recipientPerson: string;
}

export interface WarehouseHistoryResponse extends Response {
  documents: WarehouseHistoryData[];
}
// END WAREHOUSES

// ORDERS
export interface OrderData extends OrderDetailFormData {
  user: string;
  products: OrderProduct[];
  status?: OrderStatus;
}

export interface OrderDetailFormData {
  orderPerson: string;
  orderPersonEmail: string;
  recipientName: string;
  recipientPerson: string;
  recipientPhone: string;
  recipientEmail: string;
  recipientAddress: string;
  recipientCity: string;
  recipientPostalCode: string;
}

export interface OrderSummaryData {
  products: OrderSummaryProduct[];
  orderPerson: string;
  orderPersonEmail: string;
  recipientName: string;
  recipientPerson: string;
  recipientPhone: string;
  recipientEmail: string;
  recipientAddress: string;
  recipientCity: string;
  recipientPostalCode: string;
}

export interface OrderSummaryProduct {
  name: string;
  amount: number;
}

export enum OrderStatus {
  SUCCESS = 'SUCCESS',
  IN_PROGRESS = 'IN_PROGRESS',
  REJECTED = 'REJECTED'
}

export interface OrderProduct {
  productId: number;
  amount: number;
}

export interface OrderHistoryResponse extends Response {
  documents: OrderData[];
}
export interface AddOrderResponse extends Response { }

export interface OrderToAccept extends OrderDetailFormData {
  id: string;
  date: string;
  status: OrderStatus;
  user: string;
  userName: string;
  products: OrderToAcceptProduct[];
  newProducts: OrderNewProduct[],
  reason?: string;
  isValid?: boolean;
}

export interface OrderToAcceptProduct {
  id: string;
  productId: number;
  orderId: string;
  code: string;
  productName: string;
  amount: string;
  price: string;
  isValid?: boolean;
  photo: string | null;
  link: string;
  isNew?: boolean;
  isDeleted?: boolean;
}

export interface OrderNewProduct {
  productId?: number;
  code?: string;
  productName?: string;
  amount?: string;
  isValid?: boolean;
  photo?: string | null;
  link?: string;
}

export interface OrdersToAcceptResponse extends Response {
  documents: OrderToAccept[];
}

export interface OrderHistory {
  id: string;
  date: string;
  status: OrderStatus;
  user: string;
  products: OrderHistoryProduct[];
  reason?: string;
}

export interface OrderHistoryProduct {
  id: string;
  productId: string;
  code: string;
  productName: string;
  amount: string;
}

export interface OrdersWithStatusResponse extends Response {
  documents: OrderHistory[];
}

export interface OrderToAcceptResponse extends Response { }
// END ORDERS

export enum PermissionType {
  USER_TYPE_USER = 'USER_TYPE_WMS_USER',
  USER_TYPE_ADMIN = 'USER_TYPE_WMS_ADMIN',
  USER_TYPE_NONE = 'USER_TYPE_NONE',
}

export interface LoggedUser {
  ID: number;
  display_name: string;
  wmsPermissionType: PermissionType;
  employeePermissionType: PermissionType;
  user_email: string;
  user_login: string;
  user_nicename: string;
  user_registered: string;
}

export interface LoggedUserResponse extends Response {
  loggedUser: LoggedUser;
}

export interface UedEmailsResponse extends Response {
  documents: string[];
}

// FILES
export interface FilesData { }
export interface FilesResponse extends Response {
  filePaths: string[];
}

export interface FileData { }
export interface FileResponse extends Response { }


export interface Base64Image {
  base64File: string;
}

export interface Base64ImageResponse extends Response {
  filePath: string;
}
// END FILES

export interface Response {
  success: boolean;
  message?: string;
  errors?: string[];
}

export interface SendOrderToUedEmails {
  orderId: string;
  emails: string[];
}

export interface SendOrderToUedResponse extends Response { }


export interface EmployeeData {
  id?: number | null;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  street: string;
  streetNumber: string;
  postalCode: string;
  city: string;
  groupId: number;
}

export interface EmployeeResponse extends Response {
  id: string;
}

export interface EmployeesResponse extends Response {
  documents: [EmployeeData];
}

export interface GroupData {
  id?: number | null;
  name: string;
  employeeCount?: number;
}

export interface GroupResponse extends Response {
  id: string;
}

export interface GroupsResponse extends Response {
  documents: [GroupData];
}

export interface GroupAddUserToOrder {
  userId: string;
}

export interface GroupAddGroupToOrder {
  groupId: string;
}

export interface GroupAddProductToOrder {
  productId: string;
  amount: number;
}

export interface GroupOrderData {
  orders: GroupOrder[]
}
export interface GroupOrder extends GroupOrderEmployeeData {
  products: GroupOrderProduct[],
  recipientId: number;
  isExpanded?: boolean;
}

export interface GroupOrderEmployeeData {
  orderPerson: string;
  orderPersonEmail: string;
  recipientName: string;
  recipientPerson: string;
  recipientPhone: string;
  recipientEmail: string;
  recipientAddress: string;
  recipientCity: string;
  recipientPostalCode: string;
}

export interface GroupOrderProduct {
  productId: number | null;
  amount: number;
}

export interface AddGroupOrderResponse extends Response {
  groupOrderId: number;
}

export interface SendGroupOrderToUedEmails {
  groupOrderId: number;
  emails: string[];
}

export interface SendGroupOrderToUedResponse extends Response { }

export interface XlsxFileData {
  orderId?: number;
  groupOrderId?: number;
}
