import * as yup from 'yup';

export const employeeInitialState = {
  id: null,
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  street: '',
  streetNumber: '',
  postalCode: '',
  city: '',
  groupId: ''
};

export const employeeSchema = yup.object().shape({
  id: yup.number().nullable(),
  firstName: yup.string().required('Imię jest wymagane'),
  lastName: yup.string().required('Nazwisko jest wymagane'),
  phone: yup.string().required('Telefon jest wymagany'),
  email: yup.string().email('Wpisz poprawny adres e-mail').required('Wpisz adres e-mail'),
  street: yup.string().required('Wpisz nazwę ulicy'),
  streetNumber: yup.string().required('Wpisz numer ulicy'),
  postalCode: yup.string().required('Wpisz kod pocztowy'),
  city: yup.string().required('Wpisz miasto'),
  groupId: yup.string()
});
