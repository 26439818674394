import * as React from 'react';
import ReactLoading from 'react-loading';

function AppMaskComponent() {
  return (
    <div className="app-mask">
      <ReactLoading type={'spin'} color={'blue'} height={38} width={38} />
    </div>
  );
}

export default AppMaskComponent;
