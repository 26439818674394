import * as React from 'react';
import { useGetOrderProductsQuery, useGetOrdersWithStatusQuery } from '../../services/order.service';
import OrderFormComponent from './orderForm.component';
import OrderHistoryComponent from './orderHistory.component';

function OrderingComponent() {
  const historyTabRef = React.useRef<any>();

  const { refetch: refetchNewOrderProducts } = useGetOrderProductsQuery();
  const { refetch: refetchOrderHistory } = useGetOrdersWithStatusQuery();

  const handleRefetchData = (type: string) => {
    switch (type) {
      case 'newOrder':
        refetchNewOrderProducts();
        break;
      case 'orderHistory':
        refetchOrderHistory();
        break;
    }
  };

  return (
    <>
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        <li className="nav-item" role="presentation">
          <a
            className="nav-link active"
            onClick={() => handleRefetchData('newOrder')}
            id="new-order-tab"
            data-toggle="tab"
            href="#new-order"
            role="tab"
            aria-controls="new-order"
            aria-selected="true">
            Nowe zamówienie
          </a>
        </li>
        <li className="nav-item" role="presentation">
          <a
            ref={historyTabRef}
            className="nav-link"
            onClick={() => handleRefetchData('orderHistory')}
            id="order-history-tab"
            data-toggle="tab"
            href="#order-history"
            role="tab"
            aria-controls="order-history"
            aria-selected="false">
            Historia zamówień
          </a>
        </li>
      </ul>
      <div className="tab-content" id="myTabContent">
        <div className="tab-pane fade show active" id="new-order" role="tabpanel" aria-labelledby="new-order-tab">
          <OrderFormComponent historyTabRef={historyTabRef} />
        </div>
        <div className="tab-pane fade" id="order-history" role="tabpanel" aria-labelledby="order-history-tab">
          <OrderHistoryComponent />
        </div>
      </div>
    </>
  );
}

export default OrderingComponent;
