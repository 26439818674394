import * as React from 'react';
import _ from 'lodash';
import { ReactComponent as IconReject } from '../../assets/reject-icon.svg';
import { ReactComponent as IconAccept } from '../../assets/accept-icon.svg';
import { ReactComponent as IconEdit } from '../../assets/edit-icon.svg';
import {
  useAcceptOrderMutation,
  useGetOrdersToAcceptQuery,
  useRejectOrderMutation,
  useSendOrderToUedEmailsMutation
} from '../../services/order.service';
import { OrderDetailFormData, OrderNewProduct, OrderToAccept, OrderToAcceptProduct, WarehouseProductData } from '../../store/types';
import AcceptanceModalComponent from './acceptanceModal.component';
import RejectionModalComponent from './rejectionModal.component';
import { useAppDispatch } from '../../store/hooks';
import { showToast } from '../../features/appSlice';
import ImageComponent from '../application/image.component';
import { ReactComponent as IconInfo } from '../../assets/info-icon.svg';
import { ReactComponent as IconAdd } from '../../assets/add-icon.svg';
import { useGetWarehouseProductsQuery } from '../../services/warehouse.service';
import SendOrderToUedModalComponent from './sendOrderToUedModal.component';
import { dateToString } from '../../utils/dateformat';
import OrderFormDetailsComponent from '../application/orderFromDetails.component';

interface OrderErrors {
  orderId: string;
  errors: string[];
}

function OrdersComponent() {
  const { data: ordersToAccept, refetch: refeatchOrderToAccept } = useGetOrdersToAcceptQuery();
  const { data: warehouseData } = useGetWarehouseProductsQuery();
  const [selectedOrderNumber, setSelectedOrderIndex] = React.useState<number | null>(null);
  const [warehouseStock, setWarehouseStock] = React.useState<WarehouseProductData[]>([]);
  const [orders, setOrders] = React.useState<OrderToAccept[]>([]);
  const [appErrors, setAppErrors] = React.useState<OrderErrors>({ orderId: '', errors: [] });
  const [lastAcceptedOrderId, setLastAcceptedOrderId] = React.useState<string>();
  const openUedModalRef = React.useRef<any>();
  const [productList, setProductList] = React.useState<WarehouseProductData[]>([]);
  const [invalidOrderIds, setInvalidOrderIds] = React.useState<string[]>([]);

  const dispatch = useAppDispatch();
  const [acceptOrder] = useAcceptOrderMutation();
  const [rejectOrder] = useRejectOrderMutation();
  const [sendOrderToUed] = useSendOrderToUedEmailsMutation();

  React.useEffect(() => {
    if (warehouseData && _.get(warehouseData, 'documents', []).length) {
      setWarehouseStock(warehouseData.documents);
      setProductList(warehouseData.documents);
      if (orders.length) {
        setOrders([...orders]);
      }
    } else {
      setWarehouseStock([]);
      setProductList([]);
    }
  }, [warehouseData]);

  React.useEffect(() => {
    if (ordersToAccept && _.get(ordersToAccept, 'documents', []).length) {
      setOrders(ordersToAccept.documents);
    } else {
      setOrders([]);
    }
  }, [ordersToAccept]);

  const handleSendOrderlToUed = async (uedEmails: string[]): Promise<void> => {
    // send request to UED here for lastAcceptedOrderId
    const response = await sendOrderToUed({
      orderId: lastAcceptedOrderId,
      emails: uedEmails
    }).unwrap();

    dispatch(
      showToast({
        show: true,
        type: response.success ? 'success' : 'error',
        message: response.success ? 'Zamówienie zostało wysłane do UED' : 'Wystąpił błąd, skontaktuj się z administratorem'
      })
    );

    setLastAcceptedOrderId(undefined);
  };

  const handleAcceptanceOrder = async (orderIndex: number): Promise<void> => {
    const currentOrder = { ...orders[orderIndex] };
    setAppErrors({ orderId: '', errors: [] });

    try {
      // CLEAR DATA FROM EMPTY PRODUCT (AMOUNT < 1)
      currentOrder.products = clearOrderFromEmptyProducts(currentOrder.products);
      setLastAcceptedOrderId(currentOrder.id);

      const response = await acceptOrder(currentOrder).unwrap();

      dispatch(
        showToast({
          show: true,
          type: response.success ? 'success' : 'error',
          message: response.success ? 'Zamówienie zostało zaakceptowane' : 'Wystąpił błąd, sprawdź zamówienie'
        })
      );

      closeAllCollapse(`#order-${orderIndex}-collapse`);
      openUedModalRef.current.click();
    } catch (response: any) {
      if ((response.data && response.data.message === 'order-is-accepted') || response.data.message === 'order-is-rejected') {
        dispatch(
          showToast({
            show: true,
            type: 'error',
            message: response.data.message === 'order-is-accepted' ? 'Zamówienie zostało już zaakceptowane' : 'Zamówienie zostało już odrzucone'
          })
        );
        refeatchOrderToAccept();
      }

      if (_.get(response, 'data.errors', []).length) {
        setAppErrors({ orderId: currentOrder.id, errors: response.data.errors });
      }
      setLastAcceptedOrderId(undefined);
    }
  };

  const handleRejectOrder = async (orderIndex: number, reason?: string): Promise<void> => {
    const currentOrder = { ...orders[orderIndex] };
    currentOrder.reason = reason;

    const response = await rejectOrder(currentOrder).unwrap();

    dispatch(
      showToast({
        show: true,
        type: response.success ? 'success' : 'error',
        message: response.success ? 'Zamówienie zostało odrzucone' : 'Wystąpił błąd'
      })
    );
    closeAllCollapse(`#order-${orderIndex}-collapse`);
  };

  const closeAllCollapse = (elementClass: string): void => {
    const el: Element | null = document.querySelector(elementClass);
    if (el && el.classList) {
      el.classList.remove('show');
    }
  };

  const handleOrderFormDetailChange = (orderId: string, data: Partial<OrderDetailFormData>): void => {
    const tmpOrders: OrderToAccept[] = [...orders];
    const findedOrderIndexById = _.findIndex(tmpOrders, { id: orderId });

    if (findedOrderIndexById !== undefined) {
      tmpOrders[findedOrderIndexById] = { ...tmpOrders[findedOrderIndexById], ...data };
      setOrders([...tmpOrders]);
    } else {
      throw new Error('Order not found on list!');
    }
  };

  const addNewProduct = (orderIndex: number): void => {
    const tmpOrders: OrderToAccept[] = [...orders];
    const currentOrder: OrderToAccept = { ...tmpOrders[orderIndex] };

    if (!currentOrder.newProducts) {
      currentOrder.newProducts = [];
    }

    currentOrder.newProducts.push({});
    tmpOrders[orderIndex] = currentOrder;

    setOrders([...tmpOrders]);
  };

  const deleteNewProduct = (orderIndex: number, productIndex: number): void => {
    const tmpOrders: OrderToAccept[] = [...orders];
    const currentOrder: OrderToAccept = { ...tmpOrders[orderIndex] };

    currentOrder.newProducts = currentOrder.newProducts.filter((newProduct: OrderNewProduct, index: number) => index !== productIndex);
    tmpOrders[orderIndex] = currentOrder;
    setOrders([...tmpOrders]);
  };

  const deleteProduct = (orderIndex: number, productIndex: number): void => {
    const tmpOrders: OrderToAccept[] = [...orders];
    const currentOrder: OrderToAccept = { ...tmpOrders[orderIndex] };

    currentOrder.products = currentOrder.products.filter((product: OrderNewProduct, index: number) => index !== productIndex);
    tmpOrders[orderIndex] = currentOrder;
    setOrders([...tmpOrders]);
  };

  const updateNewProductData = (orderIndex: number, productIndex: number, selectedProductId: number): void => {
    const tmpOrders: OrderToAccept[] = [...orders];
    const currentOrder: OrderToAccept = { ...tmpOrders[orderIndex] };

    const productForWarehouse = productList.find((warehouseProduct: WarehouseProductData) => warehouseProduct.productId === selectedProductId);

    currentOrder.newProducts = currentOrder.newProducts.map((newProduct: OrderNewProduct, index: number) => {
      if (index === productIndex && productForWarehouse) {
        newProduct.productId = selectedProductId;
        newProduct.code = productForWarehouse.code;
        newProduct.amount = '1';
        newProduct.isValid = true;
        newProduct.productName = productForWarehouse.productName;
      }

      if (index === productIndex && !selectedProductId) {
        delete newProduct.productId;
        delete newProduct.code;
        delete newProduct.amount;
        delete newProduct.isValid;
        delete newProduct.productName;
      }

      return newProduct;
    });

    tmpOrders[orderIndex] = currentOrder;
    setOrders([...tmpOrders]);
  };

  const changeNewProductValue = (orderIndex: number, productIndex: number, amount: string): void => {
    const tmpOrders: OrderToAccept[] = [...orders];
    const currentOrder: OrderToAccept = { ...tmpOrders[orderIndex] };
    const isValid = checkFieldValue(amount);

    currentOrder.newProducts = currentOrder.newProducts.map((newProduct: OrderNewProduct, index: number) => {
      if (index === productIndex) {
        newProduct.amount = amount;
        newProduct.isValid = isValid;
      }
      return newProduct;
    });

    tmpOrders[orderIndex] = currentOrder;
    setOrders([...tmpOrders]);
  };

  const clearOrderFromEmptyProducts = (products: OrderToAcceptProduct[]): OrderToAcceptProduct[] => {
    return products.filter((product) => parseInt(product.amount) > 0);
  };

  const checkFieldValue = (value: string): boolean => {
    const parsedValue = parseInt(value);

    if (!parsedValue || (parsedValue && parsedValue < 1)) {
      return false;
    }

    return true;
  };

  const changeProductValue = (orderIndex: number, productIndex: number, value: string): void => {
    const tmpOrders: OrderToAccept[] = [...orders];
    const currentOrder = { ...tmpOrders[orderIndex] };
    const isValid = checkFieldValue(value);

    currentOrder.products = currentOrder.products.map((product: OrderToAcceptProduct, index: number) => {
      const prod = { ...product };

      // UPDATE AMOUNT FOR CURRENT PRODUCT
      if (index === productIndex) {
        prod.isValid = isValid;
        prod.amount = value;
      }
      return prod;
    });

    tmpOrders[orderIndex] = currentOrder;

    setOrders([...tmpOrders]);
  };

  const checkAllProductsIsValid = (products: OrderToAcceptProduct[]): boolean => {
    let isValid = true;
    let productWithValueExist = false;

    if (!products.length) {
      return true;
    }

    products.map((product: OrderToAcceptProduct) => {
      if (product.isValid === false) {
        isValid = false;
      }

      if (parseInt(product.amount) > 0) {
        productWithValueExist = true;
      }
    });
    return isValid && productWithValueExist;
  };

  const checkAllNewProductsIsValid = (newOrderProducts: OrderNewProduct[]): boolean => {
    let isValid = true;
    let productWithValueExist = false;

    if (!newOrderProducts || !newOrderProducts.length) {
      return true;
    }

    newOrderProducts.map((product: OrderNewProduct) => {
      if (product.isValid === false || !product.productId) {
        isValid = false;
      }

      if (product.amount && parseInt(product.amount) > 0) {
        productWithValueExist = true;
      }
    });

    return isValid && productWithValueExist;
  };

  const checkOrderDetailIsValid = (orderId: string): boolean => {
    return !invalidOrderIds.includes(orderId);
  };

  const someProductExist = (products: OrderNewProduct[], newProducts: OrderNewProduct[] = []): boolean => {
    return !!(products.length || newProducts.length);
  };

  const setOrderIsValid = (orderId: string, isValid: boolean) => {
    if (isValid && invalidOrderIds.includes(orderId)) {
      setInvalidOrderIds([...invalidOrderIds.filter((orderId: string) => orderId !== orderId)]);
    } else if (!isValid && !invalidOrderIds.includes(orderId)) {
      setInvalidOrderIds([...invalidOrderIds, orderId]);
    }
  };

  const renderProductsSelector = (orderIndex: number, productIndex: number): React.ReactElement<HTMLSelectElement> => {
    return (
      <select
        className="form-control order-new-product-list"
        onChange={(e) => updateNewProductData(orderIndex, productIndex, parseInt(e.target.value))}>
        <option value="">Wybierz produkt</option>
        {renderProductOptions(orderIndex)}
      </select>
    );
  };

  const renderProductOptions = (orderIndex: number): React.ReactElement<HTMLOptionElement[]> | null => {
    if (!productList) {
      return null;
    }

    const tmpOrders: OrderToAccept[] = [...orders];
    const currentOrder: OrderToAccept = { ...tmpOrders[orderIndex] };
    const orderNewProductIds: number[] = [];
    const orderProductIds: number[] = currentOrder.products.map((orderProduct: OrderToAcceptProduct) => orderProduct.productId);

    currentOrder.newProducts.map((newOrderPorduct: OrderNewProduct) => {
      if (newOrderPorduct.productId) {
        orderNewProductIds.push(newOrderPorduct.productId);
      }
    });

    const productListTmp: WarehouseProductData[] = [...productList].filter(
      (product: WarehouseProductData) => !orderNewProductIds.includes(product.productId) && !orderProductIds.includes(product.productId)
    );

    return (
      <>
        {productListTmp.map((product: WarehouseProductData, index: number) => {
          return (
            <option value={product.productId} key={`single-option-${index}`}>
              {`${product.code} - ${product.productName} (${product.amount} szt.)`}
            </option>
          );
        })}
      </>
    );
  };

  const renderOrderProductList = (orderIndex: number, products: OrderToAcceptProduct[], newProducts: OrderNewProduct[]) => {
    let productData: React.ReactElement[] = [];

    if (products && !_.isEmpty(products)) {
      productData = products.map((product: OrderToAcceptProduct, productIndex: number) => {
        const rowClass = productIndex % 2 === 0 ? 'white-row' : 'grey-row';

        const productStock: WarehouseProductData = warehouseStock.filter(
          (warehouseProduct: WarehouseProductData) => warehouseProduct.productId === product.productId
        )[0];

        return (
          <>
            <tr className={rowClass} key={`single-order-product-${productIndex}`}>
              <td>{productIndex + 1}</td>
              <td>{product.code}</td>
              <td>{product.productName}</td>
              <td className="p-relative">
                <ImageComponent photo={product.photo} />
              </td>
              <td>
                {product.link ? (
                  <div className="link-wrapper" onClick={() => window.open(product.link, '_blank', 'noreferrer')}>
                    <IconInfo width={24} />{' '}
                  </div>
                ) : (
                  'brak'
                )}
              </td>
              <td>
                <input
                  type="number"
                  onChange={(e) => changeProductValue(orderIndex, productIndex, e.target.value)}
                  value={product.amount}
                  name="product-1"
                  id="product-1"
                />
                {product.isValid === false && <div className="error-message">Minimalna ilość to 1</div>}
              </td>
              <td>
                <span className="warehouse-count">{productStock ? productStock.amount : 0}</span>
              </td>
              <td>
                <div onClick={() => deleteProduct(orderIndex, productIndex)}>
                  <IconReject className="action-icon reject-order" width={24} />
                </div>
              </td>
            </tr>
          </>
        );
      });
    }

    if (newProducts && newProducts.length) {
      newProducts.map((product: OrderNewProduct, productIndex: number) => {
        const rowClass = productIndex % 2 === 0 ? 'new-light-blue-row' : 'new-blue-row';

        const productStock: WarehouseProductData = warehouseStock.filter(
          (warehouseProduct: WarehouseProductData) => product.productId && warehouseProduct.productId === product.productId
        )[0];

        productData.push(
          <tr className={rowClass} key={`single-order-new-product-${productIndex}`}>
            <td>{products.length + productIndex + 1}</td>
            <td>{product.code || '-'}</td>
            <td>{product.productId ? product.productName : renderProductsSelector(orderIndex, productIndex)}</td>
            <td className="p-relative">{product.photo ? <ImageComponent photo={product.photo} /> : product.productId ? 'brak' : '-'}</td>
            <td>
              {product.link ? (
                <div className="link-wrapper" onClick={() => window.open(product.link, '_blank', 'noreferrer')}>
                  <IconInfo width={24} />{' '}
                </div>
              ) : product.productId ? (
                'brak'
              ) : (
                '-'
              )}
            </td>
            <td>
              {product.productId ? (
                <input
                  onChange={(e) => changeNewProductValue(orderIndex, productIndex, e.target.value)}
                  type="number"
                  value={product.amount}
                  name="product-1"
                  id="product-1"
                />
              ) : (
                '-'
              )}
              {product.isValid === false && <div className="error-message">Minimalna ilość to 1</div>}
            </td>
            <td>
              <span className="warehouse-count">{productStock ? productStock.amount : 0}</span>
            </td>
            <td>
              <div onClick={() => deleteNewProduct(orderIndex, productIndex)}>
                <IconReject className="action-icon reject-order" width={24} />
              </div>
            </td>
          </tr>
        );
      });
    }

    if (!productData.length) {
      productData.push(
        <tr>
          <td colSpan={8}>brak produktów do wyświetlenia</td>
        </tr>
      );
    }

    productData.push(
      <>
        <tr key="button-add">
          <td colSpan={8} className="p-0">
            <div className="add-product-button d-flex align-items-center justify-content-center p-2" onClick={() => addNewProduct(orderIndex)}>
              <span className="mr-3">Dodaj produkt</span>
              <IconAdd width={24} />{' '}
            </div>
          </td>
        </tr>
      </>
    );

    return productData;
  };

  const renderOrderList = () => {
    if (orders && !_.isEmpty(orders)) {
      return orders.map((order: OrderToAccept, orderIndex: number) => {
        const rowClass = orderIndex % 2 === 0 ? 'white-row' : 'grey-row';
        const productsCount: number = order.products.length;
        const productsCostValue: number = _.sumBy(order.products, (product) => {
          const productAmount = product.amount ? parseInt(product.amount) : 0;
          return productAmount * parseInt(product.price);
        });
        const orderIsValid =
          checkAllProductsIsValid(order.products) &&
          checkAllNewProductsIsValid(order.newProducts) &&
          checkOrderDetailIsValid(order.id) &&
          someProductExist(order.products, order.newProducts);
        const orderErrors = _.get(appErrors, 'orderId') === order.id ? _.get(appErrors, 'errors') : null;

        const orderFormDetailData: OrderDetailFormData = {
          orderPerson: order.orderPerson,
          orderPersonEmail: order.orderPersonEmail,
          recipientName: order.recipientName,
          recipientPerson: order.recipientPerson,
          recipientPhone: order.recipientPhone,
          recipientEmail: order.recipientEmail,
          recipientAddress: order.recipientAddress,
          recipientCity: order.recipientCity,
          recipientPostalCode: order.recipientPostalCode
        };

        return (
          <>
            <tr className={`${rowClass} ${orderErrors ? 'has-error' : ''}`} key={`single-order-${orderIndex}`}>
              <td>{orderIndex + 1}</td>
              <td>{order.userName}</td>
              <td>{dateToString(order.date)}</td>
              <td className="text-center">{productsCostValue} zł</td>
              <td className="text-center">{productsCount} produktów</td>
              <td>
                <div className="icons-wrapper">
                  <div
                    data-toggle="collapse"
                    data-target={`#order-${orderIndex}-collapse`}
                    aria-expanded="false"
                    aria-controls={`order-${orderIndex}-collapse`}>
                    <IconEdit className="action-icon edit-order" width={24} />
                  </div>
                  {orderIsValid && (
                    <div data-toggle="modal" onClick={() => setSelectedOrderIndex(orderIndex)} data-target="#acceptOrderModal">
                      <IconAccept className="action-icon accept-order" width={24} />
                    </div>
                  )}
                  <div data-toggle="modal" onClick={() => setSelectedOrderIndex(orderIndex)} data-target="#rejectOrderModal">
                    <IconReject className="action-icon reject-order" width={24} />
                  </div>
                </div>
              </td>
            </tr>
            <tr key={`single-order-detail-${orderIndex}`}>
              <td className="order-details" colSpan={6}>
                <div className="collapse" id={`order-${orderIndex}-collapse`}>
                  <table className="table order-product-list-table">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Nazwa produktu</th>
                        <th scope="col">Kod produktu</th>
                        <th scope="col">Zdjęcie</th>
                        <th scope="col">Link</th>
                        <th scope="col">Ilość w zamówieniu</th>
                        <th scope="col">Ilość na magazynie</th>
                        <th scope="col">Akcja</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orderErrors && (
                        <tr>
                          <td colSpan={12}>
                            <div className="error-message mt-16 w-100">
                              {appErrors.errors.map((error: string, index: number) => {
                                return <div key={`form-error-${index}`}>{error}</div>;
                              })}
                            </div>
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td colSpan={8}>
                          <OrderFormDetailsComponent
                            orderId={order.id}
                            onChange={handleOrderFormDetailChange}
                            initialData={orderFormDetailData}
                            setOrderIsValid={setOrderIsValid}
                          />
                        </td>
                      </tr>
                      {renderOrderProductList(orderIndex, order.products, order.newProducts)}
                    </tbody>
                  </table>
                </div>
              </td>
            </tr>
          </>
        );
      });
    }

    return (
      <tr>
        <td colSpan={6}>brak zamówień do akceptacji</td>
      </tr>
    );
  };

  return (
    <>
      <table className="table order-table">
        <thead className="thead-dark">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Zamawiający</th>
            <th scope="col">Data</th>
            <th scope="col">Wartość zamówienia</th>
            <th scope="col">Ilość produktów</th>
            <th scope="col">Akcja</th>
          </tr>
        </thead>
        <tbody>{renderOrderList()}</tbody>
      </table>
      <div data-toggle="modal" ref={openUedModalRef} data-target="#sendOrderToUedModal">
        <IconAccept className="hidden-button action-icon accept-order" width={24} />
      </div>
      <SendOrderToUedModalComponent handleSendOrderlToUed={handleSendOrderlToUed} modalHref="sendOrderToUedModal" />
      <AcceptanceModalComponent handleAcceptanceOrder={handleAcceptanceOrder} selectedOrderIndex={selectedOrderNumber} />
      <RejectionModalComponent handleRejectOrder={handleRejectOrder} selectedOrderIndex={selectedOrderNumber} />
    </>
  );
}

export default OrdersComponent;
