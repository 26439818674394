import * as yup from 'yup';

export const warehouseProductInitialState = {
  productId: '',
  amount: 0
};

export const warehouseProductSchema = yup.object().shape({
  id: yup.string(),
  productId: yup.string().required('To pole jest wymagane'),
  amount: yup.number().typeError('Ilość musi być numerem').min(0, 'Minimalna wartość magazynowa to 0').required('ilość magazynowa jest wymagana')
});
