import * as React from 'react';
import _ from 'lodash';
import { OrderData, OrderProduct, OrderSummaryData, ProductData } from '../../store/types';
import { useAddNewOrderMutation, useGetOrderProductsQuery } from '../../services/order.service';
import { ReactComponent as IconInfo } from '../../assets/info-icon.svg';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFieldArray, useForm } from 'react-hook-form';
import { orderInitialState, orderSchema } from '../administration/schemas/orderSchema';
import { showPopup } from '../../features/appSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import ImageComponent from '../application/image.component';
import OrderSummaryComponent from './orderSummary.component';
import OrderFormDetailsComponent from '../application/orderFromDetails.component';
import GalleryComponent from '../application/gallery.component';

interface OrderFormType {
  historyTabRef: any;
}

function OrderFormComponent({ historyTabRef }: OrderFormType) {
  const dispatch = useAppDispatch();

  const [query, setQuery] = React.useState<string>('');
  const loggedUser = useAppSelector((state) => state.app.loggedUser);
  const [showTooltipInfo, setShowTooltipInfo] = React.useState<boolean>(false);
  const [products, setProducts] = React.useState<ProductData[]>([]);
  const [appErrors, setAppErrors] = React.useState<string[]>([]);
  const [summaryData, setSummaryData] = React.useState<OrderSummaryData | null>(null);

  const { data: orderProductsData, refetch } = useGetOrderProductsQuery();
  const [addNewOrder] = useAddNewOrderMutation();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const {
    control,
    reset,
    register,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(orderSchema)
  });
  const { fields } = useFieldArray({ name: 'products', control });

  React.useEffect(() => {
    if (loggedUser) {
      setValue('orderPerson', loggedUser.display_name);
      setValue('orderPersonEmail', loggedUser.user_email);
      setValue('recipientPerson', loggedUser.display_name);
    }
    resetForm();
  }, [loggedUser]);

  // FILTER QUERY
  React.useEffect(() => {
    if (products && products.length) {
      if (query) {
        const filteredProducts = products.map((product: ProductData) => {
          const prod = { ...product };
          let finded = false;

          if (prod.productName.match(new RegExp(query, 'gi'))) {
            finded = true;
          }

          if (prod.code.match(new RegExp(query, 'gi'))) {
            finded = true;
          }

          if (prod.subProductName.match(new RegExp(query, 'gi'))) {
            finded = true;
          }

          if (!finded) {
            prod.isHidden = true;
          } else {
            prod.isHidden = false;
          }

          return prod;
        });

        setProducts([...filteredProducts]);
      } else {
        const filteredProducts = products.map((product: ProductData) => {
          product.isHidden = false;
          return product;
        });

        setProducts([...filteredProducts]);
      }
    }
  }, [query]);

  // SET FETCHED PRODUCTS
  React.useEffect(() => {
    if (orderProductsData && orderProductsData.documents) {
      const allProducts: ProductData[] = orderProductsData.documents;

      const preparedProducts: any = [];
      allProducts.map((product: ProductData) => {
        preparedProducts.push({ productId: product.id, amount: 0 });
      });

      setValue('products', preparedProducts);
      setProducts(allProducts);
    }
  }, [orderProductsData]);

  const onSubmit = handleSubmit(async (orderData: Partial<OrderData>) => {
    setAppErrors([]);

    const preparedOrder = preareOrderProductsWithAmount(orderData);

    if (!_.get(preparedOrder, 'products', []).length) {
      setAppErrors(['Aby dodać zamówienie, musisz wpisać przynajmniej jedną ilość większą niz 0']);
      return;
    }

    const orderSummaryData: OrderSummaryData = {
      products: [],
      orderPerson: preparedOrder.orderPerson || '',
      orderPersonEmail: preparedOrder.orderPersonEmail || '',
      recipientName: preparedOrder.recipientName || '',
      recipientPerson: preparedOrder.recipientPerson || '',
      recipientPhone: preparedOrder.recipientPhone || '',
      recipientEmail: preparedOrder.recipientEmail || '',
      recipientAddress: preparedOrder.recipientAddress || '',
      recipientCity: preparedOrder.recipientCity || '',
      recipientPostalCode: preparedOrder.recipientPostalCode || ''
    };

    if (preparedOrder.products) {
      preparedOrder.products.map((product: OrderProduct) => {
        const productById = products.find((prod: ProductData) => prod.id == product.productId);
        if (productById) {
          orderSummaryData.products.push({
            name: productById.productName,
            amount: product.amount
          });
        }
      });
    }

    setSummaryData(orderSummaryData);
  });

  const submitData = async () => {
    setIsLoading(true);

    setAppErrors([]);
    const preparedOrder = preareOrderProductsWithAmount(getValues());

    try {
      const response = await addNewOrder(preparedOrder).unwrap();

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);

      if (response.success) {
        resetOrderProductsAmount();
        resetForm();
        setSummaryData(null);

        historyTabRef.current.click();
        dispatch(
          showPopup({
            show: true,
            message: `Dziękujemy za złożenie zamówienia. W tym momencie czeka ono na autoryzację przez Administratorki.
            Jego status możesz zweryfikować w zakładce „Historia zamówień”.
            </br> </br>
            Postaramy się, aby paczka była niedługo w drodze do twojego domu.
            O wysyłce zostaniesz poinformowana/-y mailowo.`
          })
        );
      }
    } catch (response: any) {
      if (_.get(response, 'data.errors', []).length) {
        setAppErrors(response.data.errors);
        setSummaryData(null);
        setIsLoading(false);
      }
    }
  };

  const resetForm = () => {
    const formdata = orderInitialState;
    formdata.products = getValues('products');

    if (loggedUser) {
      formdata.orderPerson = loggedUser.display_name;
      formdata.orderPersonEmail = loggedUser.user_email;
      formdata.recipientPerson = loggedUser.display_name;
    }

    reset({ ...formdata });
  };

  const preareOrderProductsWithAmount = (orderData: Partial<OrderData>): Partial<OrderData> => {
    let filteredProducts: OrderProduct[] = [];

    if (orderData.products) {
      filteredProducts = orderData.products = orderData.products.filter((product: OrderProduct) => {
        return product.amount > 0;
      });
    }

    orderData.products = filteredProducts;
    return orderData;
  };

  const resetOrderProductsAmount = () => {
    if (fields && fields.length) {
      fields.map((field: Record<'id', string>, i: number) => {
        setValue(`products.${i}.amount`, 0);
      });
    }
  };

  const renderOrderProducts = () => {
    const someFilteredProductExist = products && !!products.filter((product: ProductData) => !product.isHidden).length;

    if (fields && fields.length && someFilteredProductExist) {
      return fields.map((product: Record<'id', string>, index: number) => {
        const currentProductData: ProductData = products[index];

        if (!currentProductData) {
          return;
        }

        if (currentProductData.isHidden) {
          return;
        }

        const photoPaths: string[] = [];

        if (currentProductData.photo1) {
          photoPaths.push(currentProductData.photo1);
        }

        if (currentProductData.photo2) {
          photoPaths.push(currentProductData.photo2);
        }

        if (currentProductData.photo3) {
          photoPaths.push(currentProductData.photo3);
        }

        const rowClass = index % 2 === 0 ? 'white-row' : 'grey-row';

        return (
          <tr className={rowClass} key={`order-prod-row-${index}`}>
            <td>{index + 1}</td>
            <td className="p-relative">
              <GalleryComponent photos={photoPaths} productIndex={index} />
            </td>
            <td>
              {currentProductData.link ? (
                <div className="link-wrapper" onClick={() => window.open(currentProductData.link, '_blank', 'noreferrer')}>
                  <IconInfo width={24} />
                </div>
              ) : (
                'brak'
              )}
            </td>
            {/* <td>Zdjęcie</td> */}
            <td>{currentProductData.code}</td>
            <td>{currentProductData.productName}</td>
            <td>{currentProductData.subProductName}</td>
            <td>{currentProductData.amount} szt.</td>
            <td className="order-product-amount-wrapper">
              <input key={currentProductData.code + currentProductData.productName} {...register(`products.${index}.amount`)} type="number" />
              {_.get(errors, `products.[${index}].amount`) && (
                <span className="error-message">{_.get(errors, `products.[${index}].amount.message`)}</span>
              )}
            </td>
          </tr>
        );
      });
    }

    return (
      <tr>
        <td colSpan={7}>brak dostępnych produktów do wyświetlenia</td>
      </tr>
    );
  };

  const renderAppErrors = () => {
    if (!appErrors.length) {
      return;
    }

    return (
      <div className="error-message mt-16">
        {appErrors.map((error: string, index: number) => {
          return <div key={`form-error-${index}`}>{error}</div>;
        })}
      </div>
    );
  };

  const mouseEnter = () => {
    setShowTooltipInfo(true);
  };

  const mouseLeave = () => {
    setShowTooltipInfo(false);
  };

  return (
    <>
      <form onSubmit={onSubmit} className="order-form">
        <div className="row mt-16">
          <div className="col-6">
            <div className="form-group">
              <label htmlFor="search">Szukaj produktu</label>
              <input
                type="text"
                name="search-by"
                className="form-control"
                onChange={(e) => setQuery(e.target.value)}
                placeholder="Podaj frazę której szukasz"
              />
            </div>
          </div>
        </div>
        <table className="table">
          <thead className="thead-dark">
            <tr>
              <th scope="col">#</th>
              <th scope="col">Zdjęcia</th>
              <th scope="col">Link</th>
              <th scope="col">Kod</th>
              <th scope="col">Nazwa</th>
              <th scope="col">Produkt</th>
              <th scope="col">Stan magazynowy</th>
              <th scope="col">Wpisz ilość</th>
            </tr>
          </thead>
          <tbody>{renderOrderProducts()}</tbody>
        </table>
        <OrderFormDetailsComponent errorsData={errors} registerData={register} />
        {renderAppErrors()}
        {orderProductsData && Boolean(orderProductsData.documents.length) && (
          <div className="buttons-wrapper">
            <button type="submit" disabled={isLoading} className="submit-button btn btn-primary">
              Złóż zamówienie
            </button>
          </div>
        )}
        <div onMouseEnter={mouseEnter} onMouseLeave={mouseLeave} className="free-delivery-fixed"></div>
        {showTooltipInfo && (
          <div className="free-delivery-stripe noselect">
            Znaj umiar, nie przesadzaj z ilością, zamów tylko tyle ile rzeczywiście potrzebujesz!{' '}
            <div className="free-delivery-triangle noselect"></div>
          </div>
        )}
      </form>
      {summaryData && (
        <OrderSummaryComponent isLoading={isLoading} summaryData={summaryData} onSubmit={submitData} onCancel={() => setSummaryData(null)} />
      )}
    </>
  );
}

export default OrderFormComponent;
