import * as yup from 'yup';

export const productInitialState = {
  code: '',
  clientCode: '',
  productName: '',
  subProductName: '',
  categoryName: '',
  unit: '',
  amount: '',
  price: '',
  link: '',
  photo1: '',
  photo2: '',
  photo3: '',
  files: [],
  isInvisible: false
};

export const productSchema = yup.object().shape({
  id: yup.string(),
  code: yup.string().required('To pole jest wymagane'),
  clientCode: yup.string().required('To pole jest wymagane'),
  productName: yup.string().required('To pole jest wymagane'),
  subProductName: yup.string().required('To pole jest wymagane'),
  categoryName: yup.string().required('To pole jest wymagane'),
  unit: yup.string().required('Wymagane'),
  amount: yup.string().required('Wymagane'),
  price: yup.string().required('Wymagane'),
  link: yup.string(),
  photo1: yup.string().nullable(),
  photo2: yup.string().nullable(),
  photo3: yup.string().nullable(),
  files: yup.array(),
  isInvisible: yup.boolean(),
});
