export const dateToString = (dateString: string) => {
  const formatter = new Intl.DateTimeFormat('pl', {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false
  });

  const year = dateString.slice(0, 4);
  const formattedDate = formatter.format(new Date(dateString));
  const dateWithoutTime = formattedDate.slice(0, -6);
  const dateTime = formattedDate.substr(-5);

  return `${dateWithoutTime} ${year}, ${dateTime}`;
}