import * as React from 'react';
import GroupEmployeesComponent from './groupEmployees.component';
import GroupsComponent from './groups.component';
import { useGetGroupsQuery } from '../../services/group.service';
import { useAppDispatch } from '../../store/hooks';
import { setGroups } from '../../features/groupSlice';
import _ from 'lodash';
import GroupOrderComponent from './groupOrder.component';

function GroupHomeComponent() {
  const dispatch = useAppDispatch();
  const { data: groupData } = useGetGroupsQuery();
  const { refetch: refetchGetGroups } = useGetGroupsQuery();

  React.useEffect(() => {
    if (groupData && _.get(groupData, 'documents', []).length) {
      dispatch(setGroups(groupData.documents));
    } else {
      dispatch(setGroups([]));
    }
  }, [groupData]);

  return (
    <>
      <ul className="nav nav-tabs mt-3" id="groupOrderTab" role="tablist">
        <li className="nav-item" role="presentation">
          <a
            className="nav-link active"
            id="group-order-tab"
            data-toggle="tab"
            href="#group-order"
            role="tab"
            aria-controls="group-order"
            aria-selected="false">
            Wyślij zamówienie grupowe
          </a>
        </li>
        <li className="nav-item" role="presentation">
          <a className="nav-link" id="users-tab" data-toggle="tab" href="#users" role="tab" aria-controls="users" aria-selected="true">
            Uzytkownicy wysyłki grupowej
          </a>
        </li>
        <li className="nav-item" role="presentation" onClick={() => refetchGetGroups()}>
          <a className="nav-link" id="groups-tab" data-toggle="tab" href="#groups" role="tab" aria-controls="groups" aria-selected="false">
            Grupy wysyłkowe
          </a>
        </li>
      </ul>
      <div className="tab-content" id="groupOrderTabContent">
        <div className="tab-pane fade show active" id="group-order" role="tabpanel" aria-labelledby="group-order-tab">
          <GroupOrderComponent/>
        </div>
        <div className="tab-pane fade" id="users" role="tabpanel" aria-labelledby="users-tab">
          <GroupEmployeesComponent />
        </div>
        <div className="tab-pane fade" id="groups" role="tabpanel" aria-labelledby="groups-tab">
          <GroupsComponent />
        </div>
      </div>
    </>
  );
}

export default GroupHomeComponent;
