import * as React from 'react';
import _ from 'lodash';
import { ReactComponent as IconShow } from '../../assets/show-icon.svg';
import { useGetOrdersWithStatusQuery } from '../../services/order.service';
import { OrderHistory, OrderHistoryProduct, OrderStatus } from '../../store/types';
import TooltipComponent from '../application/tooltip.component';
import { dateToString } from '../../utils/dateformat';

function OrderHistoryComponent() {
  const { data: ordersWithStatus } = useGetOrdersWithStatusQuery();

  const renderHistoryDetail = (historyProducts: OrderHistoryProduct[]) => {
    if (historyProducts && !_.isEmpty(historyProducts)) {
      return historyProducts.map((orderHistory: OrderHistoryProduct, historyProduct: number) => {
        const rowClass = historyProduct % 2 === 0 ? 'white-row' : 'grey-row';

        return (
          <tr className={rowClass} key={`single-hist-${historyProduct}`}>
            <td>{historyProduct + 1}</td>
            <td>{orderHistory.code}</td>
            <td>{orderHistory.productName}</td>
            <td>{orderHistory.amount}</td>
          </tr>
        );
      });
    }
    return (
      <tr>
        <td colSpan={4}>brak szczegółów zamówienia do wyświetlenia</td>
      </tr>
    );
  };

  const renderOrdersHistory = () => {
    if (ordersWithStatus && !_.isEmpty(ordersWithStatus.documents)) {
      return ordersWithStatus.documents.map((orderHistory: OrderHistory, index: number) => {
        const rowClass = index % 2 === 0 ? 'white-row' : 'grey-row';
        let status = <td></td>;

        switch (orderHistory.status) {
          case OrderStatus.IN_PROGRESS:
            status = <td className="status-inprogress">Do akceptacji</td>;
            break;
          case OrderStatus.SUCCESS:
            status = <td className="status-accepted">Zaakceptowane</td>;
            break;
          case OrderStatus.REJECTED:
            status = <td className="status-rejected">Odrzucone</td>;
            break;
        }

        return (
          <React.Fragment key={`order-with-status-${index}`}>
            <tr className={rowClass} key={`history-collapse-${index}`}>
              <td>{index + 1}</td>
              <td>{dateToString(orderHistory.date)}</td>
              <td>{orderHistory.products.length} produktów</td>
              {status}
              <td>
                <div className="icons-wrapper">
                  <div
                    data-toggle="collapse"
                    data-target={`#order-history-${index}-collapse`}
                    aria-expanded="false"
                    aria-controls={`order-history-${index}-collapse`}>
                    <IconShow className="action-icon show-order" width={30} />
                  </div>
                  {orderHistory.reason && <TooltipComponent text={orderHistory.reason} />}
                </div>
              </td>
            </tr>
            <tr>
              <td className="order-details" colSpan={5}>
                <div className="collapse" id={`order-history-${index}-collapse`}>
                  <table className="table order-product-list-table">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Kod produktu</th>
                        <th scope="col">Nazwa produktu</th>
                        <th scope="col">ilość</th>
                      </tr>
                    </thead>
                    <tbody>{renderHistoryDetail(orderHistory.products)}</tbody>
                  </table>
                </div>
              </td>
            </tr>
          </React.Fragment>
        );
      });
    }

    return (
      <tr>
        <td colSpan={5}>brak zamówień do wyświetlenia</td>
      </tr>
    );
  };

  return (
    <>
      <table className="table history-table">
        <thead className="thead-dark">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Data zamówienia</th>
            <th scope="col">Ilość produktów</th>
            <th scope="col">Status</th>
            <th scope="col">Akcja</th>
          </tr>
        </thead>
        <tbody>{renderOrdersHistory()}</tbody>
      </table>
    </>
  );
}

export default OrderHistoryComponent;
