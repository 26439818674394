import React, { useEffect } from 'react';
import { EmployeeData, GroupData } from '../../store/types';
import { useGetEmployeesQuery, useGetGroupsQuery } from '../../services/group.service';
import GroupFormComponent from './groupForm.component';
import { ReactComponent as IconEdit } from '../../assets/edit-icon.svg';
import { ReactComponent as IconEmployees } from '../../assets/user-list-icon.svg';
import { useAppDispatch } from '../../store/hooks';
import { setEditedGroup } from '../../features/groupSlice';
import _ from 'lodash';
import GroupEmployeesModal from './groupEmployeesModal.component';

function GroupsComponent() {
  const dispatch = useAppDispatch();
  const openGroupEmployeeModalRef = React.useRef<any>();
  const [isCollapsing, setIsCollapsing] = React.useState<boolean>(false);
  const addGroupInputRef = React.useRef<any>(null);
  const [employeesFromGroup, setEmployeesFromGroup] = React.useState<EmployeeData[]>([]);
  const [allEmployees, setAllEmployees] = React.useState<EmployeeData[]>([]);
  const [selectedGroupName, setSelectedGroupName] = React.useState<string>('');
  const { data: employeeData } = useGetEmployeesQuery();
  const { data: groupData } = useGetGroupsQuery();

  useEffect(() => {
    if (employeeData && employeeData.documents) {
      setAllEmployees(employeeData.documents);
    }
  }, [employeeData]);

  const checkIfGroupAlreadyExist = (groupName: string) => {
    if (groupData && !_.isEmpty(groupData.documents)) {
      let allGroups: GroupData[] = groupData.documents;
      return !!allGroups.find((group: GroupData) => group.name === groupName);
    }
    return false;
  };

  const handleEditGroup = (groupId: number) => {
    if (groupData && _.get(groupData, 'documents', []).length) {
      const editedGroup: GroupData | undefined = groupData.documents.find((group: GroupData) => group.id === groupId);

      if (editedGroup) {
        dispatch(setEditedGroup(editedGroup));

        if (addGroupInputRef.current && !isCollapsing) {
          addGroupInputRef.current.click();
        }
        window.scrollTo(0, 0);
      }
    }
  };

  const handleShowEmployees = (groupId: number, groupName: string) => {
    const employeesFromGroup = allEmployees.filter((employee: EmployeeData) => employee.groupId === groupId);
    setEmployeesFromGroup(employeesFromGroup);
    setSelectedGroupName(groupName);
    openGroupEmployeeModalRef.current.click();
  };

  const renderGroupList = () => {
    if (groupData && !_.isEmpty(groupData.documents)) {
      let groups: GroupData[] = groupData.documents;

      if (!groups.length) {
        return (
          <tr>
            <td align="center" colSpan={11}>
              brak grup do wyświetlenia
            </td>
          </tr>
        );
      }

      return groups.map((group: GroupData, index: number) => {
        const rowClass = index % 2 === 0 ? 'white-row' : 'grey-row';

        return (
          <tr className={`${rowClass}`} key={`single-group-row-${index}`}>
            <td>{index + 1}</td>
            <td>{group.name}</td>
            <td>{group.employeeCount}</td>
            <td>
              <div className="d-flex align-items-center group-list-action-wrapper">
                <div onClick={() => group.id && handleEditGroup(group.id)}>
                  <IconEdit width={24} />
                </div>
                {!!group.employeeCount && (
                  <div className="ml-1" onClick={() => group.id && handleShowEmployees(group.id, group.name)}>
                    <IconEmployees width={28} />
                  </div>
                )}
              </div>
            </td>
          </tr>
        );
      });
    }

    return (
      <tr>
        <td align="center" colSpan={11}>
          brak grup do wyświetlenia
        </td>
      </tr>
    );
  };

  return (
    <>
      <GroupFormComponent
        isCollapsing={isCollapsing}
        setIsCollapsing={setIsCollapsing}
        addGroupInputRef={addGroupInputRef}
        checkIfGroupAlreadyExist={checkIfGroupAlreadyExist}
      />
      <table className="table">
        <thead className="thead-dark">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Nazwa grupy</th>
            <th scope="col">Ilość pracowników</th>
            <th scope="col" style={{ width: '50px' }}>
              Akcja
            </th>
          </tr>
        </thead>
        <tbody>{renderGroupList()}</tbody>
      </table>
      <div className="hidden-button" data-toggle="modal" ref={openGroupEmployeeModalRef} data-target="#groupEmployeesModal"></div>
      <GroupEmployeesModal groupName={selectedGroupName} employees={employeesFromGroup} />
    </>
  );
}

export default GroupsComponent;
