import * as React from 'react';
import ReactCrop, { PercentCrop, PixelCrop, centerCrop, convertToPixelCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import setCanvasPreview from './canvasPreview.component';
import { useDeleteFileMutation, useUploadBase64ImageMutation } from '../../services/file.service';
import ReactLoading from 'react-loading';
import { API_HOST } from '../../store/types';
import { ReactComponent as IconDelete } from '../../assets/reject-icon.svg';

interface CropperType {
  fileName: string;
  setValue: Function;
  photoNumber: number;
  imagePath: string;
}

const ASPECT_RATIO = 1;
const MIN_DIMENSION = 50;
const FILE_SIZE_LIMIT = 1; //MB

function CropperComponent({ photoNumber, fileName, setValue, imagePath }: CropperType) {
  const closeModalRef = React.useRef<any>();
  const inputRef = React.useRef<any>(null);

  const imgRef = React.useRef<HTMLImageElement>(null);
  const previewCanvasRef = React.useRef<HTMLCanvasElement>(null);
  const [imgSrc, setImgSrc] = React.useState('');
  const [crop, setCrop] = React.useState<PercentCrop>();
  const [uploadImage, { isLoading }] = useUploadBase64ImageMutation();
  const [deleteFile] = useDeleteFileMutation();
  const [limitError, setLimitError] = React.useState<string>('');

  const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLimitError('');

    const file = e.target.files?.[0];
    if (!file) return;

    const fileSize = file.size / 1024 ** 2;

    if (fileSize > FILE_SIZE_LIMIT) {
      setLimitError('Limit zdjęcia został przekroczony');
      return;
    }

    const reader = new FileReader();
    reader.addEventListener('load', (e) => {
      const imageElement: HTMLImageElement = new Image();
      const imageUrl: string = reader.result?.toString() || '';
      imageElement.src = imageUrl;
      setImgSrc(imageUrl);
    });

    reader.readAsDataURL(file);
  };

  const onImageLoad = (e: any) => {
    const { width, height } = e.currentTarget;
    const cropWidthInPercent: number = (MIN_DIMENSION / width) * 100;

    const crop: PercentCrop = makeAspectCrop(
      {
        unit: '%',
        width: cropWidthInPercent
      },
      ASPECT_RATIO,
      width,
      height
    );
    const centeredCrop: PercentCrop = centerCrop(crop, width, height);
    setCrop(centeredCrop);
  };

  const handleClickAddPhoto = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.click();
    }
  };

  const getImageUrl = async () => {
    if (crop && previewCanvasRef && previewCanvasRef.current && imgRef && imgRef.current) {
      setCanvasPreview(imgRef.current, previewCanvasRef.current, convertToPixelCrop(crop, imgRef.current.width, imgRef.current.height));

      const dataUrl: string = previewCanvasRef.current.toDataURL();
      const data = await uploadImage({ base64File: dataUrl }).unwrap();
      setValue(fileName, data.filePath);
      setImgSrc('');
      closeModalRef.current.click();
    }
  };

  const removeImage = async () => {
    await deleteFile({ filePath: imagePath }).unwrap();
    setValue(fileName, '');
  };

  return (
    <div>
      {!imagePath && (
        <button
          type="button"
          className="submit-button btn btn-primary mr-3"
          data-toggle="modal"
          data-target={`#imageCropperModal_photo${photoNumber}`}>
          Dodaj zdjęcie nr. {photoNumber}
        </button>
      )}

      {imagePath && (
        <div className="mt-3 cropped-image-wrapper">
          <img alt="" className="product-img" style={{ maxWidth: '194px' }} src={`${API_HOST}${imagePath}`} />
          <div className="cropped-image-remove-button" onClick={removeImage}>
            <IconDelete className="action-icon reject-order" width={24} />
          </div>
        </div>
      )}

      <div
        className="image-modal modal fade"
        id={`imageCropperModal_photo${photoNumber}`}
        tabIndex={-1}
        role="dialog"
        aria-labelledby={`imageCropperModal_photo${photoNumber}`}
        aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addProductImage">
                Dodaj zdjęcie
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <h6>
                Po wybraniu zdjęcia, zaznacz myszką obszar aby wykadrować zdjęcie. Kadr zawsze będzie kwadratem. Możesz dowolnie go powiększać i
                zmniejszać. <strong>Limit zdjęcia to 1MB.</strong>
              </h6>
              {limitError && <div className="error-message">{limitError}</div>}
              <button type="button" onClick={handleClickAddPhoto} className="submit-button btn btn-primary mr-3">
                Wybierz zdjęcie
              </button>
              <input type="file" accept="image/*" className="input-file" ref={inputRef} onChange={onSelectFile} />

              {imgSrc && (
                <ReactCrop
                  crop={crop}
                  onChange={(pixelCrop: PixelCrop, percentCrop: PercentCrop) => setCrop(percentCrop)}
                  // circularCrop
                  keepSelection
                  // aspect={ASPECT_RATIO}
                  minWidth={MIN_DIMENSION}
                  minHeight={MIN_DIMENSION}>
                  <img ref={imgRef} src={imgSrc} alt="Upload" style={{ maxHeight: '70vh' }} onLoad={onImageLoad} />
                </ReactCrop>
              )}
              {crop && (
                <canvas
                  ref={previewCanvasRef}
                  className="mt-4"
                  style={{
                    display: 'none',
                    border: '1px solid black',
                    objectFit: 'contain',
                    width: 150,
                    height: 150
                  }}
                />
              )}
              <div className="row">
                <div className="col-12 d-flex justify-content-end">
                  <button type="button" ref={closeModalRef} data-dismiss="modal" className="submit-button hidden-button btn btn-primary"></button>

                  {!isLoading && (
                    <div className="d-flex">
                      <button type="button" className="cancel-button btn btn-secondary mr-2" data-dismiss="modal">
                        ANULUJ
                      </button>
                      {imgSrc && !isLoading && (
                        <button type="button" onClick={getImageUrl} className="submit-button btn btn-primary">
                          KADRUJ I DODAJ
                        </button>
                      )}
                      {isLoading && (
                        <div className="cropper-loader-wrapper">
                          <ReactLoading type={'spin'} color={'blue'} height={38} width={38} />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CropperComponent;
