import * as React from 'react';
import { WarehouseProductData } from '../../store/types';
import _ from 'lodash';
import { ReactComponent as IconEdit } from '../../assets/edit-icon.svg';
import { useAppDispatch } from '../../store/hooks';
import WarehouseFormComponent from './warehouseForm.component';
import { useGetWarehouseProductsQuery } from '../../services/warehouse.service';
import { setEditedProduct, setProducts } from '../../features/warehouseSlice';
import { useGetProductsQuery } from '../../services/product.service';

function WarehousesComponent() {
  const dispatch = useAppDispatch();
  const { data: warehouseData } = useGetWarehouseProductsQuery();
  const { data: productData } = useGetProductsQuery();

  const [warehouseProductIds, setWarehouseProductIds] = React.useState<number[]>([]);
  const [isCollapsing, setIsCollapsing] = React.useState<boolean>(false);
  const addProductInputRef = React.useRef<any>(null); //@TODO TYPE FOR REF
  const [query, setQuery] = React.useState<string>('');

  // SET PRODUCT LIST TO STORE
  React.useEffect(() => {
    if (productData && _.get(productData, 'documents', []).length) {
      dispatch(setProducts(productData.documents));
    }
  }, [productData]);

  React.useEffect(() => {
    if (warehouseData && _.get(warehouseData, 'documents', []).length) {
      setWarehouseProductIds(
        warehouseData.documents.map((warehouseProduct) => {
          return warehouseProduct.productId;
        })
      );
    }
  }, [warehouseData]);

  const handleEditProduct = (productCode: string) => {
    if (warehouseData && !_.isEmpty(warehouseData.documents)) {
      const editedProduct: WarehouseProductData | undefined = warehouseData.documents.find(
        (product: WarehouseProductData) => product.code === productCode
      );

      if (editedProduct) {
        dispatch(setEditedProduct(editedProduct));
        if (addProductInputRef.current && !isCollapsing) {
          addProductInputRef.current.click();
        }
        window.scrollTo(0, 0);
      }
    }
  };

  const renderWarehouseProductList = () => {
    if (warehouseData && !_.isEmpty(warehouseData.documents)) {
      let allProducts: WarehouseProductData[] = warehouseData.documents;

      if (query) {
        allProducts = allProducts.filter((product: WarehouseProductData) => {
          let finded = false;

          if (product.productName.match(new RegExp(query, 'gi'))) {
            finded = true;
          }

          if (product.categoryName.match(new RegExp(query, 'gi'))) {
            finded = true;
          }

          if (product.code.match(new RegExp(query, 'gi'))) {
            finded = true;
          }

          if (product.subProductName.match(new RegExp(query, 'gi'))) {
            finded = true;
          }

          return finded;
        });
      }

      return allProducts.map((product: WarehouseProductData, index: number) => {
        let rowClass = index % 2 === 0 ? 'white-row' : 'grey-row';
        const isEmptyStock: boolean = parseInt(product.amount) === 0;

        if (isEmptyStock) {
          rowClass += ' is-empty-stock';
        }

        return (
          <tr className={rowClass} key={`single-prod-row-${index}`}>
            <td>{index + 1}</td>
            <td>{product.code}</td>
            <td>{product.productName}</td>
            <td>{product.subProductName}</td>
            <td>{product.categoryName}</td>
            <td className="text-center">{product.amount} szt.</td>
            <td className="text-center">{product.reservationAmount || 0} szt.</td>
            <td>
              <div onClick={() => handleEditProduct(product.code)}>
                <IconEdit width={24} />
              </div>
            </td>
          </tr>
        );
      });
    }

    return (
      <tr>
        <td colSpan={12}>brak produktów na magazynie do wyświetlenia</td>
      </tr>
    );
  };

  return (
    <>
      <WarehouseFormComponent
        isCollapsing={isCollapsing}
        setIsCollapsing={setIsCollapsing}
        addProductInputRef={addProductInputRef}
        warehouseProductIds={warehouseProductIds}
      />
      <div className="row">
        <div className="col-6">
          <div className="form-group">
            <label htmlFor="search">Szukaj produktu</label>
            <input type="text" className="form-control" onChange={(e) => setQuery(e.target.value)} placeholder="Podaj frazę której szukasz" />
          </div>
        </div>
      </div>
      <table className="table">
        <thead className="thead-dark">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Kod</th>
            <th scope="col">Nazwa</th>
            <th scope="col">Produkt</th>
            <th scope="col">Kategoria</th>
            <th scope="col">Stan magazynowy</th>
            <th scope="col">Rezerwacje</th>
            <th scope="col">Akcja</th>
          </tr>
        </thead>
        <tbody>{renderWarehouseProductList()}</tbody>
      </table>
    </>
  );
}

export default WarehousesComponent;
